export const uz_messages = {
  yes: "Ha",
  no: "Yo'q",
  main: "Asosiy",
  yes_doctor: "Shifokor bilan aloqa",
  social: "Qayerdan bildingiz",
  refresh: "Yangilash",
  reset: "O'chirish",
  delete_success: "O'chirish yakunlandi",
  delete_calcel: "O'chirish bekor qilindi",
  delete_notification: "Bu faylni butunlay o'chirib tashlaydi. Davom etish?",
  warning: "Ogohlantirish",
  cancel: "Bekor qilish",
  c_sum: "so'm",
  test: "Test",
  total: "Jami summa",
  price: "Narx",
  measurement: "O'lchov",
  cost_amount: "Tannarxi",
  sale_amount: "Sotuv narxi",
  name: "Nomi",
  available: "Mavjud",
  code: "Kod",
  date_0: "Sana",
  day: "Kun",
  warehouse: "Ombor",
  confirmed: "Tasdiqlangan",
  confirm: "Tasdiqlash",
  warehouses_0: "Omborlar",
  main_warehouses: "Asosiy omborlar",
  warehouse_movement: "Omborni ko'chirish",
  date_movement: "Ko'chirilgan sana",
  show_drug: "Dorilarni ko'rish",
  date: "Yaratilgan sana",
  update_date: "O'zgartirilgan sana",
  rate: "Kurs",
  search: "Qidirish",
  search_0: "Qidiruv",
  number: "Raqami",
  day_in_warehouse: "Kun omborda",
  total_price: "Jami narx",
  start_date: "Sanadan",
  end_date: "Sanagacha",
  all_date: "Sana",
  from: "dan",
  save_and_create_new: "Saqlash va yangisini qo'shish",
  for_bussiness: "Yuridik shaxs",
  for_personal: "Jismoniy shaxs",
  contact: "Kontakt",
  bank_account: "Hisob raqami",
  short_name: "Qisqa nomi",
  full_name: "To'liq nomi",
  phone_number: "Telefon raqami",
  fax: "Fax",
  phone: "Telefon",
  email: "Elektron pochta",
  email_address: "E-mail manzili",
  address: "Manzil",
  legal_address: "Rasmiy manzil",
  inn: "STIR",
  mfo: "MFO",
  onlis: "ONLIS",
  okonx: "OKONX",
  oked: "OKED",
  rkp_nds: "RKP QQS",
  nds: "QQS",
  sku: "SKU",
  bank: "Bank",
  correspondent_account: "Kor. akkaunt",
  accaunt: "Akkaunt",
  position: "Lavozim",
  type: "Turi",
  lack_of: "Mavjud emas",
  paid: "To'langan",
  not_paid: "To'lanmagan",
  number_card: "Ambulator karta raqami",
  doctor: "Shifokor",
  doctor_0: "Shifokor",
  doctor_name: "Shifokor (F.I.Sh.)",
  pay: "To'lash",
  payments_report: "To'lovlar hisoboti",
  enumeration: "Pul o'tkazmasi",
  terminal: "Terminal",
  cach: "Naqd pul",
  comment: "Izoh",
  all_service: "Barcha xizmatlar",
  show: "Ko'rsatilgan",
  showing: "Ko'rish",
  website_name: "Veb-sayt nomini ko'rsating",
  name_service: "Xizmat nomi",
  provision: "Shart",
  status_condition: "Holat statusi",
  to_history: "tarixga",
  name_0: "Nomi",
  until: "gacha",
  show_0: "Ko'rish",
  hidden: "Belgilanmagan",
  doctor_can: "Shifokoringizga ko'rining",
  color: "Rang",
  aggrement: "Shartnomalar",
  date_aggrement: "Shartnoma sanasi",
  term_aggrement: "Shartnoma muddati",
  price_aggrement: "Shartnoma summasi",
  order_amput: "Xizmat summasi",
  deal_amount: "Shartnoma summasi",
  remaind: "Qoldiq",
  back: "Orqaga",
  balance: "Balans",
  all_not_paid_price: "Umumiy summa to'lanmagan",
  search_order: "Buyurtmalarni qidirish",
  search_order0: "Buyurtmani qidirish",
  pay_order: "Buyurtmalarni to'lash",
  cost_order: "Buyurtma narxi",
  not_paid_sum: "To'lanmagan summa",
  payable_sum: "To'lanadigan summa",
  contractual_basis: "Kelishuv asosida",
  view: "Ko'rinish",
  view_0: "Ko'rish",
  new_type_expence: "Yangi xarajat turi",
  update_type_expence: "Xarajat turini o'zgartirish",
  new_expence: "Yangi xarajat",
  type_expence: "Xarajat turi",
  update_expence: "Xarajatni o'zgartirish",
  active: "Faol",
  is_active: "Faol",
  npt_active: "Faol emas",
  reverse: "Orqaga",
  reverse_rate: "Teskari kurs",
  symbol: "Belgi",
  defects: "Nuqsonlar",
  drug: "Dori",
  replacement: "Almashtirish",
  templates: "Shablonlar",
  go_back: "Orqaga",
  fio: "F.I.Sh.",
  dob: "Tug'ilgan sana",
  gender: "Jins",
  clear: "O'chirish",
  save_and_go: "Saqlash va davom etish",
  next: "Keyingi",
  update: "O'zgartirish",
  return_material: "Materialni qaytarish",
  parent: "Guruh",
  parent_category: "Bosh kategoriya",
  please_choose_parent: "Iltimos, bosh kategoriyani tanlang",
  surname: "Familiya",
  first_name: "Ism",
  patronymic: "Otasining ismi",
  male: "Erkak",
  female: "Ayol",
  born_place: "Tug'ilgan joyi",
  passport_number: "Pasport raqami",
  address_register: "Ro'yxatga olingan manzili",
  arrival_date: "Kelish sanasi",
  departure_date: "Ketish sanasi",
  order_number: "Buyurtma raqami",
  rooms_number: "Xona raqami",
  name_template: "Shablon nomi",
  limit: "Chegara",
  payment_report_limit: "To'lov hisoboti chegarasi",

  pp: "T/r",
  ifo: "F.I.Sh.",
  service_name: "Xizmat nomi",
  schedule: "Jadval",
  patient: "Bemor",
  patient_service: "Bemor xizmatlari",
  patient_info: "Bemor ma'lumotlari",
  patient_0: "Bemor",
  patients: "Bemorlar",
  patients_count: "Bemorlar soni",
  reports: "Hisobotlar",
  cashbox: "Kassa",
  warehouses: "Omborlar",
  actions: "Harakatlar",
  settings: "Sozlamalar",
  forms: "Formalar (XKT-10)",
  my_office: "Kabinetim",
  hospital: "Statsionar",
  daily_hospital: "Kungi statsionar",
  printing: "Chop etish",
  log_out: "Chiqish",
  profile: "Profil",
  print_form: "Chop etish shakli",
  forms_of_documents: "Hujjat shakllari",
  document_forms_editor: "Hujjat shakllarini tahrirlash",
  source: "Manba",
  new_source: "Yangi manba",
  update_source: "Manbani o'zgartirish",
  tags: "Teglar",
  new_tag: "Yangi teg",
  update_tags: "Tegni o'zgartirish",
  service_categoy: "Xizmat kategoriyasi",
  branches: "Filiallar",
  orders: "Buyurtmalar",
  order: "Buyurtma",
  conclusions_history: "Xulosalar tarixi",
  report_doctors: "Shifokorlar hisoboti",
  report_clinics: "Shifoxona hisoboti",
  report_partner_doctors: "Hamkor shifokorlar hisoboti",
  payments: "To'lovlar",
  contracts: "Shartnomalar",
  payment_reports: "To'lovlar hisoboti",
  payments_for_expenses: "Xarajatlar uchun to'lovlar",
  currency: "Valyuta",
  payment_type: "To'lov turi",
  new_payment_type: "Yangi to'lov turi",
  update_payment_type: "To'lov turini o'zgartirish",
  flow_types: "Xarajat turlari",
  stasionary_cashier: "Statsionar kassasi",
  users: "Foydalanuvchilar",
  user: "Foydalanuvchi",
  roles: "Rollar",
  role: "Rol",
  role_name: "Rol nomi",
  update_role: "Rolni o'zgartirish",
  new_role: "Yangi rol",
  access_rights: "Kirish huquqlari",
  access_right: "Kirish huquqi",
  update_access_rights: "Kirish huquqlarini o'zgartirish",
  choose_status: "Iltimos, statusni tanlang",
  print_audit: "Chop etiladigan audit",
  show_audit: "Auditni ko'rish",
  work_schedule: "Ish jadvali",
  specialities: "Mutaxassisliklar",
  new_specialities: "Yangi mutaxassislik",
  update_specialities: "Mutaxassislikni o'zgartirish",
  rooms: "Xonalar",
  new_room: "Yangi xona",
  services: "Xizmatlar",
  service: "Xizmat",
  service_type: "Xizmat turi",
  service_types: "Xizmat turlari",
  new_service: "Yangi xizmat",
  update_service: "Xizmatni o'zgartirish",
  partner_doctor: "Hamkor shifokor",
  new_partner_doctor: "Yangi hamkor shifokor",
  update_partner_doctor: "Hamkor shifokorni o'zgartirish",
  time: "Vaqt",
  times: "Vaqt",
  new_time: "Yangi vaqt",
  update_time: "Vaqtni o'zgartirish",
  condition: "Holat",
  organization: "Tashkilot",
  countries: "Mamlakatlar",
  country: "Mamlakat",
  new_country: "Yangi mamlakat",
  update_country: "Mamlakatni o'zgartirish",
  cities: "Shaharlar",
  city: "Shahar",
  type_documents: "Hujjat turlari",
  documents: "Hujjat",
  series_and_number: "Seriya va raqam",
  authority: "Beruvchi organ",
  date_of_issue: "Berilgan sana",
  citizenship: "Fuqarolik",
  nationality: "Millat",
  add_nationality: "Millat qo'shish",
  update_nationality: "Millatni o'zgartirish",
  region: "Viloyat (Hudud)",
  add_region: "Viloyat (Hudud) qo'shish",
  update_region: "Viloyat (Hudud)ni o'zgartirish",
  district: "Tuman",
  street: "Ko'cha",
  house: "Uy",
  flat: "Xonadon",
  photo: "Rasm (3x4sm)",
  new_photo: "Rasm qo'shish",
  add_document: "Hujjat qo'shish",
  blank_head: "Blank sarlavhasi (shapka)",
  do_you_want: "Diqqat! Haqiqatan ham buni bajarmoqchimisiz?",
  do_you_want_confirm: "Haqiqatan ham tasdiqlamoqchimisiz?",
  category_mkb: "XKT kategoriyalari",
  my_patients: "Mening bemorlarim",
  my_conclusions: "Mening xulosalarim",
  all_conclusions: "Barcha xulosalar",
  conclusions: "Xulosalar",
  patient_conclusions: "Bemor xulosasi",
  write_conclusions: "Xulosa yozish",
  show_conclusions: "Xulosani ko'rish",
  print_center: "Chop etish markazi",
  print_hitory: "Chop etish tarixi",
  procurement: "Xaridlar",
  movement: "Ko'chirish",
  add_movement: "Yangi ko'chirish",
  update_movement: "Ko'chirishni tahrirlash",
  write_offs: "Hisobdan chiqarish",
  remainder: "Qoldiq",
  remainders: "Qoldiqlar",
  products: "Mahsulotlar",
  product: "Mahsulot",
  groups: "Guruhlar",
  new_groups: "Yangi guruh",
  group: "Guruh",
  slug: "Kod",
  measurements: "O'lchov birligi",
  measurement_full: "O'lchov birliklari",
  new_measurements: "Yangi o'lchov birligi",
  suppliers: "Ta'minotchilar",
  suppliers_aggrement: "Ta'minotchilar shartnomalari",
  status: "Statuslar",
  status_0: "Status",
  medicines_on: "Dorilar N/O",
  nurse_office: "Hamshira kabineti",
  phy_office: "Fizioterapiya kabineti",
  purchasing_products: "Mahsulotlar xaridi",
  forms_mkb: "Shakllar (XKT-10)",
  schedule_cabinet: "Kabinet jadvali",
  active_patients: "Faol bemorlar",
  patients2: "Bemorlar",
  transactions: "Tranzaksiyalar",
  week: "Hafta",
  month: "Oy",
  year: "Yil",
  expences: "Xarajatlar",
  expence: "Xarajat",
  speciality: "Mutaxassislik",
  chose_a_speciality: "Mutaxassislikni tanlang",
  reception_visit: "Qabulga tashrif",
  quantity: "Miqdor",
  total_amount: "Umumiy summa",
  print: "Chop etish",
  patientServiceReport: "Xizmatlar hisoboti",
  patientResidenceReport: "Tumanlar bo'yicha hisobot",
  print_double: "2 nusxada chop etish",
  printing: "Chop etish",
  save: "Saqlash",
  create_new_patient: "Yangi bemor yaratish",
  create_new_order: "Yangi buyurtma yaratish",
  close: "Yopish",
  basic_information: "Asosiy ma'lumotlar",
  add_new_patient: "Yangi bemor qo'shish",
  update_new_patient: "Yangi bemorni o'zgartirish",
  from_hours: "Soatdan",
  hour: "Soat",
  duration: "Davomiylik",
  lasting: "Davom etishi",
  minut: "daqiqa",
  notes: "Eslatmalar",
  note: "Eslatma",
  cabinet: "Kabinet",
  contracts_n: "Shartnomalar №",
  without_calculation: "Shartnomalar №",
  select_patient: "Bemorni tanlang",
  select_patient_or_find: "Chapdan bemorni tanlang yoki yangisini qo'shing",
  start_input_find_service: "Xizmat nomini yoki raqamini kiriting",
  add: "Qo'shish",
  add_service: "Xizmat qo'shish",
  add_new_service: "Yangi xizmat qo'shish",
  subject_of_agreement: "Shartnoma mavzusi (Xizmat)",
  quantity_short: "Miqdor",
  price2: "Narx",
  discount_percent: "Chegirma (%)",
  discount_price: "Chegirma (So'm)",
  discount: "Chegirma",
  discounts: "Chegirmalar",
  amount: "Summa",
  delete: "O'chirish",
  add_new_partner_doctor: "Yangi hamkor shifokorni qo'shish",
  add_partner_doctor: "Hamkor shifokorni qo'shish",
  total: "Jami",
  totals: "Jami",
  total_0: "Jami",
  from_whom: "Kimdan",
  login: "Avtorizatsiya",
  sign_in: "Tizimga kirish",
  hospitalization: "Gospitalizatsiya",
  open: "Ochish",
  lend: "Qarzga",
  without_amount: "Hisob-kitobsiz",
  add_to_room: "Palataga yozish",
  add_to_reception: "Qabulga yozish",
  bunk: "Yotoq",
  create: "Yaratish",
  class: "Guruh",
  branch: "Filial",
  department: "Bo'lim",
  dapartment_user: "Bo'lim foydalanuvchilari",
  floor: "Qavat",
  hospitalization_type: "Gospitalizatsiya turi",
  room: "Xona",
  corps: "Korpus",
  purpose_of_hospitalization: "Gospitalizatsiya maqsadi",
  type_of_injury: "Kasallik turi",
  type_condition: "Holat turi",
  conditions: "Holat",
  patient_indicator: "Bemor ko'rsatkichlari",
  show_valid_price: "Dorilar narxini ko'rsating",
  pockets: 'Paketlar',
  indicator: "Ko'rsatgich",
  indicators: "Ko'rsatgichlar",
  add_bunk: "Yotoq qo'shish",
  add_new_bunk: "Yangi yotoq qo'shish",
  save_and_close: "Saqlash va yopish",
  add_class: "Guruh qo'shish",
  price_per_day: "Kunlik narx",
  users: "Foydalanuvchilar",
  add_department_user: "Bo'lim foydalanuvchisini qo'shish",
  add_branch: "Filial qo'shish",
  code_id: "ID kodi",
  add_floor: "Qavat qo'shish",
  add_corps: "Korpus qo'shish",
  add_type_of_injury: "Kasallik turini qo'shish",
  patient_indicators: "Bemor ko'rsatkichlari",
  add_patient_indicators: "Bemor ko'rsatkichlarini qo'shish",
  add_purpose_of_hospitalization: "Gospitalizatsiya maqsadini qo'shish",
  add_room: "Xona qo'shish",
  add_conditions: "Holat qo'shish",
  add_hospitalization_type: "Gospitalizatsiya turini qo'shish",
  update_bunk: "Yotoqni o'zgartirish",
  update_class: "Guruhni o'zgartirish",
  update_branch: "Filialni o'zgartirish",
  update_floor: "Qavatni o'zgartirish",
  update_corps: "Korpusni o'zgartirish",
  update_type_of_injury: "Kasallik turini o'zgartirish",
  update_patient_indicators: "Bemor ko'rsatkichlarini o'zgartirish",
  update_purpose_of_hospitalization:
    "Gospitalizatsiya maqsadini o'zgartirish",
  update_room: "Xonani o'zgartirish",
  update_conditions: "Holatni o'zgartirish",
  update_hospitalization_type: "Gospitalizatsiya turini o'zgartirish",
  update_department_user: "Bo'lim foydalanuvchisini o'zgartirish",
  buy_ready_product: "Mahsulot xaridi",
  delivery_deadline: "Yetkazib berish muddati",
  total_cost: "Jami narx",
  description: "Tavsif",
  receive: "Kirim",
  new_aggrement: "Yangi shartnoma",
  number_aggrement: "Shartnoma raqami",
  date_aggrement: "Shartnoma sanasi",
  sum: "Jami",
  new_product: "Yangi mahsulot",
  username: "Egasi:",
  update_product: "Mahsulotni o'zgartirish",
  new_supplier: "Yangi ta'minotchi",
  current_price: "Amaldagi narx",
  discount_cost: "Chegirma narxi",
  category_or_service: "Kategoriya yoki xizmat",
  parent_service: "Guruh xizmati",
  raw_producement: "Xom-ashyo xaridi",
  qty_come: "Kelgan miqdori",
  sale_percentage: "Sotuv foizi",
  sale_price: "Sotuv narxi",
  date_produced: "Ishlab chiqarilgan sana",
  term: "Yaroqlik muddati",
  date_receiving: "Qabul qilingan sana",
  new_status: "Yangi status",
  add_order_payment: "Buyurtma to'lovlarini qo'shish",
  order_payment: "Buyurtma to'lovlari",
  add_transaction: "Tranzaksiya qo'shish",
  history_transaction: "Tranzaksiyalar tarixi",
  new_category_service: "Yangi xizmat kategoriyasi",
  update_category_service: "Xizmat kategoriyasini o'zgartirish",
  create_citizenship: "Fuqarolik qo'shish",
  update_citizenship: "Fuqarolikni o'zgartirish",
  create_city: "Yangi shahar",
  update_city: "Shaharni o'zgartirish",
  create_condition: "Yangi holat",
  update_condition: "Holatni o'zgartirish",
  contract_parnet: "Asosiy shartnoma №",
  contract_payment: "Shartnoma bo'yicha to'lovlar",
  info_aggrement: "Shartnoma haqida ma'lumot",
  contract_service: "Shartnoma xizmatlari",
  create_currency: "Yangi valyuta",
  update_currency: "Valyutani o'zgartirish",
  order_service: "Buyurtirilgan xizmat",
  placeholder_time_date: "Sana va vaqtni tanlang",
  search_template: "Shablonni qidirish",
  choose_template: "Shablonni tanlash",
  new_type_document: "Yangi hujjat turi",
  update_type_document: "Hujjat turi o'zgartirish",
  new_patient: "Yangi bemor qo'shish",
  patient_history: "Bemorlar tarixi",
  patient_histories: "Bemorlar tarixi",
  condition_patient: "Bemor holati",
  physiotherapy: "Fizioterapiya",
  bed_schedules: "Yotoqlar jadvali",
  stationary_settings: "Statsionar sozlamalari",
  cargo_storage: "Yukni saqlash",
  hospitalization_complated: "Gospitalizatsiya tugatildi",
  complated: "Bajarildi",
  drug_instruction: "Dori / Ko'rsatma",
  info_treatment: "Davolanish uchun ma'lumot",
  how_prepare_medicine: "Dorilar qanday tayyorlanadi",
  nurse_wrong_choice: "Hamshira kataklar tanlovida xato qilgan",
  done_treatment: "Bajarildi (dori muvaffaqiyatli kiritildi)",
  patient_reject: "Bemor rad etdi",
  drug_bad_reaction: "Nuqsonlar (Dori kiritilgandan so'ng, reaksiya bo'ldi)",
  drug_good_reaction: "Keyingisi (Reaksiya bu doridan emas davom eting)",
  company_name: "Kompaniya nomi",
  update_organization: "Tashkilotni o'zgartirish",
  create_organization: "Yangi tashkilot",
  update_patient: "Bemorni o'zgartirish",
  order_count: "Buyurtmalar soni",
  count: "Soni",
  group_of_patient: "Bemorlarning yosh guruhlari",
  create_hospitalization: "Gospitalizatsiya qo'shish",
  update_hospitalization: "Gospitalizatsiyani o'zgartirish",
  date_hospitalization: "Gospitalizatsiya sanasi",
  diagnos: "Dastlabki tashxis",
  blood: "Qon guruhi",
  temperature: "Harorat",
  puls: "Qon bosimi",
  height: "Bo'yi",
  weight: "Vazni",
  allergy: "Allergiya",
  date_injury: "Kasallik sanasi",
  date_register: "Ro'yhatdan o'tgan sana",
  direction: "Yo'nalish",
  dailyTreatment: "Kundalik ko'rsatkich",
  cabinat_balance: "Mijoz balansi",
  patient_balance: "Bemor balansi",
  dailyTreatment_patient: "Bemorning kundalik ko'rsatkichlari",
  add_medicine: "Dori qo'shish",
  patient_hospitalization: "Bemorni gospitalizatsiya qilish",
  patient_report: "Bemorning barcha xulosalari",
  all_service_patient: "Bemorning barcha xizmatlari",
  patient_physiotherapy: "Bemor fizioterapiyasi",
  add_patient_physiotherapy: "Bemorga fizioterapiya qo'shish",
  add_physiotherapy: "Fizioterapiya qo'shish",
  drug_indicator_warehouse: "Dori retsepti (omborxona)",
  drug_indicator: "Dori retsepti",
  add_drug_indicator: "Dori retseptini qo'shish",
  add_drug_indicator_warehouse: "Dori retseptini qo'shish (omborxona)",
  instruction_for_use: "Foydalanish bo'yicha ko'rsatmalar",
  period: "Muddat",
  startPeriod: "Qaysi sanadan",
  endPeriod: "Qaysi sanagacha",
  purpose_quantity: "Soni",
  medicines: "Dori vositalari",
  how_many_days: "Necha kun",
  money_per_day: "Bir kun uchun narx",
  change: "Almashtirish",
  select_time: "Sana va vaqtni tanlang",
  enter_id: "Identifikatorni kiriting (№)",
  patient_or_fio: "Bemor № yoki F.I.Sh.",
  update_teg: "Tegni o'zgartirish",
  print_conclusion: "Xulosani chop etish",
  medical_history: "Kasallik tarixi",
  product_detail: "Mahsulot tafsilotlari",
  add_unit_of_measure: "O'lchov birligini qo'shish",
  filter_keyword: "Filtrdagi kalit so'z",
  filter: "Filtrlar",
  busy: "Band",
  free: "Bo'sh",
  booked: "Bron qilingan",
  quarantine: "Karantin",
  choose_category: "Kategoriyani tanlang",
  title: "sarlavha",
  running_title: "kolontitul",
  page_header: "Yuqori kolontitul",
  footer_header: "Pastki kolontitul",
  content: "Tarkib",
  income_product: "Kiruvchi mahsulotlar",
  materials_consuption: "Material sarflanishi",
  detail: "Tafsilotlar",
  new_warehouse: "Yangi ombor",
  new_user: "Yangi foydalanuvchi",
  please_enter_parol: "Iltimos, parolni kiriting",
  please_choose_role: "Iltimos, rol tanlang",
  search_doctor: "shifokorni qidirish",
  all_speciality: "Barcha mutaxassisliklar",
  speciality_doctor: "Shifokor mutaxassisligi",
  password: "Parol",
  id_code: "Identifikatsiya kodi",
  last_login: "Oxirgi kirish",
  category: "Kategoriya",
  recipient: "Qabul qiluvchi",
  used: "Ishlatilgan",
  phone_two: "Telefon-2",
  doctor_service: "Shifokor xizmatlari",
  locked: "Bloklangan",
  manufactured_date: "Ishlab chiqarilgan sana",
  percent: "Sotuv %",
  percent_: "%",
  new_price: "Bir dona uchun narx",
  from_time: "Boshlanish vaqti",
  to_time: "Tugash vaqti",
  write_off_date: "Hisobdan chiqarish sanasi",
  transmitter: "Yuboruvchi",
  write_off_products: "Mahsulotlarni hisobdan chiqarish",
  write_of_from: "Hisobdan chiqarish sanasi: ",
  delivery_time: "Yetkazib berish vaqti",
  change_write_off: "Hisobdan chiqarishni o'zgartirish",
  contract_parent: "Asosiy shartnoma №",
  order_amount: "Xizmat ko'rsatish summasi",
  drive_license: "Haydovchilik guvohnomasi",
  pieces: "dona",
  update_setting: "O'zgartirish",
  delete_setting: "O'chirish",
  adds: "Qo'shish",
  procedural_0: "Muolaja",
  procedural: "Muolaja xonasi",
  remainder_reports: "Muolaja hisoboti",
  invalid: "Nuqsonli",
  valid: "Dorilar",
  used_medicine: "Muolajada ishlatilgan",
  loading: "yuklanmoqda",
  medicine: "Mahsulotlar",
  age: "Yosh",
  agent: "Agent",
  laboratory: "Laboratoriya",
  percentage: "Foiz",
  normaLaboratory: "Norma Laboratoriya",
  normaCost: "Xarajatlar normasi",
  resourcesText: "Resurslar",
  conResourcesText: "Resurslar osti",
  patient_age_reports: "Yosh bo'yicha hisobotlar",
  express: "Express",
  expresses: "Express",
  processes: "Jarayonlar",
  process: "Jarayon",
  add_processes: "Jarayon qo'shish",
  responsible_name: "Vakil",
  material: "Material",
  add_new_partner_clinic: "Yangi klinika qo'shish",
  add_partner_clinic: "Hamkor klinika qo'shish",
  report_partner_clinics: "Hamkor klinikalar hisoboti",
  partner_clinic: "Hamkor klinika",
  partner_clinics: "Hamkor klinikalar",
  new_partner_clinic: "Yangi hamkor klinika",
  update_partner_clinic: "Hamkor klinikani o'zgartirish",
  order_id: "Buyurtma ID-si",
  patient_id: "Bemor ID-si",
  lifetime: "Muddati",
  life_time: "Muddati",
  order_created_at: "Buyurtma qabul qilingan sana",
  order_updated_at: "Buyurtma o'zgartirilgan sana",
  expired: "Muddati o'tgan",
  dailyProcessReport: "Kunlik jarayonlar hisoboti",
  my_laboratory: "Laboratoriya",
  laborant: "Laborant",
  receive_: "Qabul qilish",
  my_informal_laboratory_patients: "Laboratoriya buyurtmalari",
  my_informal_patients: "Shifokor buyurtmalari",
  bonus: "Bonus",
  bonus_amount: "Bonus",
  used_cassettes: "Foydalanilgan kasseta",
  used_glasses: "Foydalanilgan oyna (shisha)",
  paymentBonusP: "Bonus to'lovlar",
  doctorProcessReport: "Jarayonlar umumiy hisoboti",
  patients_income: "Klinikaga umumiy kelish",
  hospital_income: "Kasalxona miqdori",
  day_hospital_income: "Kundalik shifoxona miqdori",
  consultations_income: "Konsultatsiya miqdori",
  consultation: "Konsultatsiya",
  another_services_income: "Boshqa xizmatlar miqdori",
  another_services: "Boshqa xizmatlar",
  all_laboratory_conclusions: "Laboratoriya hulosalari",
  new_daily_process_report: "Yangi kunlik jarayonlar hisoboti",
  reason: "Sabab",
  new_report: "Yangi hisobot",
  update_report: "Hisobotni o'zgartirish",
  scores: "Hisoblar",
  incoming: "Kiruvchi",
  outgoing: "Chiquvchi",
  score: "Hisoblar",
  bonus_paid_amount: "To'langan",
  bonus_debt: "Qoldiq",
  excelReport: "Excel hisoboti",
  sickNumber: "XKT kodi",
  add_new_department: "Yangi bo'lim qo'shish",
  new_order: "Yangi buyurtma",
  new_discount: "Yangi chegirma",
  update_discount: "Chegirmani o'zgartirish",
  glass: "Shisha",
  add_new_doctor_sign: "Yangi imzo qo'shish",
  update_doctor_sign: "Imzoni o'zgartirish",
  doctorSign: "Imzo",
  doctorSigns: "Imzolar",
  outpatient_card: "Ambulator karta",
  letterhead: "Hamkorli",
  today_transactions: "Bugungi umumiy summa",
  payment: "To'lov",
  refund: "Qaytarish",
  transactionScores: "Balans",
  orderServiceTerm: "Sanalar bo'yicha buyurtmalar",
  typebonus: "Bonus turi",
  country_code: "Mamlakat kodi",
  tel_number: "Telefon raqami",
  exemple: "Namuna:",
  validation: "Xatolik",
  phone_number_is_not_valid: "Telefon raqami noto'g'ri",
  invoice: "Hisob-faktura",
  order_date: "Buyurtma sanasi",
  conclusion_date: "Hulosa sanasi",
  partner_amount: "Hamkor hisobi",
  id_number: "Buyurtma IDsi",
  number_of_orders_today: "Bugungi buyurtmalar soni",
  number_of_orders_month: "Oy davomida buyurtmalar soni",
  is_close: "Hulosa tasdiqlandi",
  own_doctor: "Bizning shifokor",
  process_l: "JLP jarayonlar???",
  status_waiting_paid: "To'lov kutilmoqda",
  status_laborant_free: "Laboratoriya buyurtmalari",
  status_laborant: "Laborantda",
  status_doctor_free: "Shifokorlar buyurtmalari",
  status_doctor: "Shifokorda",
  status_written: "Hulosa yozildi",
  status_closed: "Muvaffaqiyatli",
  owner_clinic: "Brend",
  new_owner_clinic: "Yangi brend",
  web_site: "Veb-sayt",
  phone_numbers: "Telefon raqamlari",
  logo: "Logotip",
  type_phone: "Telefon turi",
  final_conclusion: "Kasalxonadan chiqarish",
  saldo: "Saldo",
  add_departmentes: "Bo'lim qo'shish",
  update_departmentes: "Bo'limni o'zgartirish",
  clinic: "Klinika",
  procedural_list: "Muolajalar ro'yxati",
  man: "Erkak",
  woman: "Ayol",
  men: "Erkaklar",
  women: "Ayollar",
  daily: "Bugungi",
  weekly: "Haftalik",
  monthly: "Oylik",
  quarterly: "Choraklik",
  half_yearly: "Yarim yillik",
  yearly: "Yillik",
  totaly: "Jami",

  doctor_bonuses: "Shifokorlar bonuslari",
  partner_bonus: "Hamkorlar bonuslari",
  patient_ages: "Bemorlar yoshi",
  gender_patients: "Bemorlar jinsi",
  top_services: "TOP xizmatlar",
  type_receive: "Kirim turlari",
  type_expence: "Xarajat turlari",
  sum_receive: "Kirim summasi",
  sum_expence: "Xarajat summasi",
  paid_partner_bonus: "Hamkorlarga to'langan bonuslar",
  not_set: "Belgilanmagan",

  number_of_patients: "Bemorlar soni",
  number_of_patients_today: "Kunlik bemorlar soni",
  number_of_patients_week: "Haftalik bemorlar soni",
  number_of_patients_month: "Oylik bemorlar soni",
  number_of_patients_total: "Umumiy bemorlar soni",
  number_of_orders_today: "Kunlik buyurtmalar soni",
  number_of_orders_month: "Oylik buyurtmalar soni",
  number_of_orders_total: "Hamma buyurtmalar soni",
  written_conclusions: "Yozilgan xulosalar",
  hospital_patients_today: "Bugungi kasalxona bemorlari soni",
  hospital_patients_week: "Haftalik kasalxona bemorlari soni",
  hospital_patients_month: "Oylik kasalxona bemorlari soni",
  day_hospital_for_today: "Bugun kunduzi bolgan bemorlar soni",
  day_hospital_for_week: "Haftalik kunduzi bolgan bemorlar soni",
  day_hospital_for_month: "Oylik kunduzi bolgan bemorlar soni",
  closed_conclusions: "Tasdiqlangan xulosalar",
  number_of_written_conclusions_month: "Oylik yozilgan xulosalar",
  number_of_closed_conclusions_month: "Oylik tasdiqlangan xulosalar",

  dds: "DDS",
  blok: "Blok",
  clinic_diagnos: "Klinik tashxis",
  vial_quantity: "Flakonlar soni",
  lifetime_services: "Xizmat muddati",
  address_patient: "Bemor manzili",
  not_full: "To'liq emas",
  invoices: "Hisob-fakturalar",
  paid_invoices: "To'langan hisob-fakturalar",
  not_paid_invoices: "To'lanmagan hisob-fakturalar",
  history: "Tarix",
  materials_consuption: "Materiallar sarflanishi",
  bonuses: "Bonuslar",
  total_reports: "Umumiy hisobotlar",
  download: "Yuklab olish",
  system_settings: "Tizim sozlamalari",
  doctors_settings: "Shifokorlar sozlamalari",
  partners: "Hamkorlar",
  cashbox_print: "Kassada chop etish",
  employees_work_schedule: "Xodimlar ish jadvali",
  partner_clinic_full_logo: "Logo (to'liq)",
  partner_clinic_mini_logo: "Logo (mini)",
  partner_clinic_full_logo_active: "Logo (to'liq) aktiv",
  partner_clinic_mini_logo_active: "Logo (mini) aktiv",
  version: "Versiya",
  theme_setting: "Fon sozlamalari",
  new: "Yangi",
  old: "Eski",
  dynamic_photo: "Dinamik fon",
  light: "Yorug'",
  dark: "Qorong'u",
  cannot_print: "Chop etib bo'lmadi",
  not_paid_of_all: "Umuman to'lanmagan",
  set_transaction_amount: "Tranzaksiya summasini ko'rsating",
  overpaid_amount: "Ortiqcha to'langan summa",
  overpaid: "Ortiqcha summa to'langan",
  order_not_found: "Buyurtma topilmadi",
  operation_canceled: 'Operatsiya bekor qilindi',
  do_you_really_want_to_finish: 'Tugatishga ishonchingiz komilmi?',
  do_you_really_want_to_complete_the_hospitalization: 'Gospitalizatsiyani tugatishga ishonchingiz komilmi?',
  price_one_day_without_room_price: 'Стоимость пакета за 1 день',
  price_one_day_min_room_price: 'Минимальная сумма пакета койки за 1 день',
  price_one_day_with_room_price: 'Стоимость пакета за 1 день с койкой',
  price_with_room_price: 'Стоимость пакета за {days} день с койкой',
  order_column: 'Yangi maydon qo\'shish',
  is_report: 'To\'liq hisobot',
  is_conclusion: 'Xulosa',
  is_register: 'Ro\'yxatga olish',
  create_order_column: 'Maydon nomi',
  start_period: 'muddat boshlanishi',
  end_period: 'muddat oxiri',
  category_services: "Категории услуг",
}
