<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || $t('message.cities') "
    filterable
    clearable
    :size="size"
    class="d-block"
  >
    <el-option
      v-for="city in cities"
      :key="city.id"
      :label="city.name"
      :value="city.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: ["city_id"],
  watch: {
    city_id: {
      handler: function () {
        this.dispatch(this.city_id);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.cities && this.cities.length === 0) {
      this.getInventory();
    }
  },
  computed: {
    ...mapGetters({
      cities: "cities/inventory",
    }),
  },
  methods: {
    ...mapActions({
      getInventory: "cities/inventory",
    }),
   
  },
};
</script>