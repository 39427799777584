import { filter } from './properties/filter'
import { sort } from './../properties/sort'
import { pagination } from './../properties/pagination'
import {model} from "./properties/model";
import {rules} from "./properties/rules";

export const state = {
    list: [],
    model: JSON.parse( JSON.stringify( model )),
    filter: filter,
    pagination: JSON.parse( JSON.stringify(pagination) ),
    sort: JSON.parse( JSON.stringify(sort) ),
    rules: rules,
    current_user_id: ''
};
