import Layout from '@/layouts'

const schedule = {
  path: '/patientRoom',
  component: Layout,
  name: 'patientRoom',
  // redirect: 'patientRoom/main/:id',
  children: [{
    path: '/patientRoom',
    component: () => import('@/views/patientRoom/index'),
    name: 'patientRoom.index',
    meta: {
      title: 'Кабинет пациенты'
    },
    // redirect: '/patientRoom/main/:id',
    children: [{
      path: '/patientRoom/:id',
      component: () => import('@/views/patientRoom/components/index'),
      name: 'patientHome',
      meta: {
        title: 'Кабинет пациенты'
      }
    },
    {

      path: '/patientRoom/hospitalization/:id',
      component: () => import('@/views/patientRoom/components/hospitalization'),
      name: 'hospitalization',
      meta: {
        title: 'Кабинет пациенты'
      }

    }, {

      path: '/patientRoom/patientService/:id',
      component: () => import('@/views/patientRoom/components/patientService'),
      name: 'patientService',
      meta: {
        title: 'Кабинет пациенты'
      }

    }, {

      path: '/patientRoom/patientConclusion/:id',
      component: () => import('@/views/patientRoom/components/patientConclusion'),
      name: 'patientConclusion',
      meta: {
        title: 'Кабинет пациенты'
      }

    },
    {

      path: '/patientRoom/dailyTreatment/:id',
      component: () => import('@/views/patientRoom/components/dailyTreatment'),
      name: 'dailyTreatment',
      meta: {
        title: 'Кабинет пациенты'
      },

    },
    {

      path: '/patientRoom/physiotherapy/:id',
      component: () => import('@/views/patientRoom/components/physiotherapy'),
      name: 'physiotherapy',
      meta: {
        title: 'Кабинет пациенты'
      },

    }, {

      path: '/patientRoom/clientBalance/:id',
      component: () => import('@/views/patientRoom/components/clientBalance'),
      name: 'clientBalance',
      meta: {
        title: 'Кабинет пациенты'
      },

    },
    {

      path: '/patientRoom/finalConclusion/:id',
      component: () => import('@/views/patientRoom/components/finalConclusion'),
      name: 'finalConclusion',
      meta: {
        title: 'Кабинет пациенты'
      },

    }
    ]
  },]
}

export default schedule