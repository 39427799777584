import request from './../utils/request'


export function index(params) {
  return request({
    url: '/orders',
    method: 'get',
    params
  })
}

export function show(id) {
  return request({
    url: `/orders/${id}`,
    method: 'get'
  })
}

export function chart() {
  return request({
    url: `/payments/chart`,
    method: 'get'
  })
}

export function relation_show(params) {
  return request({
    url: `/order-payments/${params.id}`,
    method: 'get',
    params
  })
}

export function updateStatus(params) {
  return request({
    url: `/orders/status-update`,
    method: 'post',
    params
  })
}

export function store(data) {
  return request({
    url: '/orders',
    method: 'post',
    data
  })
}

export function paymentStore(data) {
  return request({
    url: '/payments',
    method: 'post',
    data
  })
}

export function contractPayments(data) {
  return request({
    url: '/payments/contractPayments',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: `/orders/${data.id}`,
    method: 'put',
    data
  })
}

export function destroy(id) {
  return request({
    url: `/orders/${id}`,
    method: 'delete',
  })
}
