<template>
  <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="plc || $t('message.services')"
      filterable
      clearable
      :size="size"
      class="d-block"
  >
    <el-option
        v-for="category in categories"
        :key="category.id"
        :label="category.name"
        :value="category.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: ["category_id"],
  watch: {
    category_id: {
      handler: function () {
        this.dispatch(this.category_id);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.categories && this.categories.length === 0) {
      this.getInventory();
    }
  },
  computed: {
    ...mapGetters({
      categories: "categoryServices/inventory",
    }),
  },
  methods: {
    ...mapActions({
      getInventory: "categoryServices/inventory",
    }),

  },
};
</script>