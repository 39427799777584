import Layout from '@/layouts'

const remainderReport = {
  path: '/remainderReport',
  component: Layout,
  name: 'remainderReportParent',
  redirect: '/remainderReport',
  children: [{
    path: '/remainderReport',
    component: () => import('@/views/remainderReport/index'),
    name: 'remainderReport',
    meta: {
      title: 'Регион'
    }
  }, ]
}

export default remainderReport