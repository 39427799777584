<template>
  <el-select
    :value="selected"
    @change="dispatch"
    :placeholder="plc ||$t('message.region') "
    filterable
    clearable
    remote
    :remote-method="searchRegionsLoading"
    v-loading="loadingData"
  >
    <el-option
      v-for="item in filtered"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";
import _ from "lodash";

export default {
  mixins: [inventory],
  props: ["region_id", "id", "state_id"],
  mounted() {
    if (this.regions && this.regions.length === 0) {
      this.getInventory();
    }
  },
  data() {
    return {
      loadingData: false,
    };
  },
  watch: {
    id: {
      handler: function () {
        setTimeout(() => {
          this.selected = this.id;
        });
      },
      deep: true,
      immediate: true,
    },
    state_id: function (val, oldVal) {
      if (val != oldVal && parseInt(oldVal, 10) == oldVal) {
        this.selected = null;
        this.$emit("input", this.selected);
      }
    },
    region_id: function (val, oldVal) {
      this.getInventory({ selected_id: val, state_id: this.state_id });
      this.selected = val;
      if (val != null) this.emitParent(val);
      this.$emit("input", this.selected);
    },
  },
  computed: {
    ...mapGetters({
      regions: "regions/inventory",
    }),
    filtered: function () {
      if (this.state_id) {
        return this.regions.filter((o) => {
          return this.state_id == o.state_id;
        });
      } else {
        return this.regions;
      }
    },
  },
  methods: {
    ...mapActions({
      getInventory: "regions/inventory",
    }),
    searchRegionsLoading(val) {
      this.loadingData = true;
      this.searchregions(val);
    },
    searchregions: _.debounce(function (val) {
      this.getInventory({ search: val, state_id: this.state_id })
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    }, 500),
    emitParent(val) {
      let state = this.regions.find((o) => {
        return val == o.id;
      });

      if (state) this.$emit("state_id", state.state_id);
    },
  },
};
</script>
