<template>
  <div class="chart-card">
    <div class="chart-card__header">
      <h2>{{title ? title : $t(`message.orders`)}}</h2>
      <div>
        <el-date-picker v-model="value1" type="daterange" :start-placeholder="$t('message.start_period')"
                        :end-placeholder="$t('message.end_period')" v-if="filterDate === 'period'">
        </el-date-picker>
      </div>
      <el-dropdown>
        <span class="el-dropdown-link">
          {{ $t(`message.${filterDate}`)
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item in filterByDate"
            @click.native="filterDate = item"
            :key="item"
            >{{ $t(`message.${item}`) }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div>
      <apexchart
        type="line"
        height="300"
        :options="chartOptions"
        :series="series"
        ref="chart"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
import moment from "moment";
import axios from "axios";

export default {
  props: ["data", "title"],

  data() {
    return {
      filterDate: "daily",
      value1: null,
      filterByDate: [
        "daily",
        "weekly",
        "monthly",
        "quarterly",
        "half_yearly",
        "yearly",
        "period",
      ],
      custom_period_value: null,
      backendUrl: process.env.VUE_APP_URL,
      statistic: [
        {
          count: 1117,
          subtitle: "Total subscriptions",
        },
        {
          count: 742,
          subtitle: "Basic subscriptions",
        },
        {
          count: 259,
          subtitle: "Pro subscriptions",
        },
      ],

      series: [
        {
          name: "Order",
          data: [
            2990, 3265, 3445, 3926, 3075, 2068, 2756, 2791, 2296, 3016, 2653, 1,
          ],
        },
      ],

      chartOptions: {
        theme: {
          mode: "light",
        },
        chart: {
          background: "transparent",
          height: 350,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "top",
        },
        stroke: {
          width: 1,
          curve: "smooth",
        },
        xaxis: {
          // type: "datetime",
          categories: ["май", "июнь", "июль"],
          tickPlacement: "between",
        },
        // tooltip: {
        //   x: {
        //     format: "dd/MM/yy HH:mm",
        //   },
        // },
        colors: ["#8b5cf6"],
      },
    };
  },
  computed: {
    ...mapGetters({
      mode: "theme/MODE",
      token: 'auth/token',
    }),
  },
  watch: {
    data: function (newVal, oldVal) {
      this.setData(newVal, this.filterDate);
    },
    filterDate: function (newVal, oldVal) {
      if (newVal === 'period') {
        this.value1 = this.custom_period_value;
      }
      this.setData(this.data, newVal);
    },
    mode(bool) {
      this.updateChart(bool);
    },
    value1: function (val) {
      if (val) {
        this.custom_period_value = val;
        this.filterDate = "period";
        this.fetchData();
      }
    },
  },
  mounted() {
    this.updateChart(this.mode);
  },
  methods: {
    ...mapActions({
      period: "dashboards/customReport"
    }),
    setData(val, active) {
      const chart = this.$refs.chart;
      this.series = [];
      this.chartOptions.xaxis.categories = [];

      const element = val[active];

      this.chartOptions.xaxis.categories = element.map((el) => el.data);
      chart.updateOptions({
        xaxis: {
          categories: element.map((el) => el.data),
        },
      });
      this.series.push({
        name: i18n.t("message.order"),
        data: element.map((item) => item.count),
      });
    },
    async fetchData() {
      await this.period({
        start_date: this.value1[0],
        end_date: this.value1[1],
        report: 'chart',
        entity: 'order',
      })
        .then((res) => {
          this.$set(this.data, 'period', res.data);
          this.setData(this.data, 'period');
        })
        .catch ((error) => {
          console.error('Ошибка при выполнении запроса:', error);
        })
    },
    updateChart(bool) {
      this.$refs.chart.updateOptions({
        theme: {
          mode: bool ? "dark" : "light",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-card {
  // chart-card__statistic
  &__statistic {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    // chart-card__statistic-item
    &-item {
      .count {
        font-size: 20px;
        font-weight: 600;
        margin: 0;
        position: relative;
      }

      .subtitle {
        color: #9ca3af;
        font-size: 13px;
        margin: 0;
      }

      &:nth-child(2) .count::before,
      &:last-child .count::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -14px;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #8b5cf6;
      }

      &:last-child .count::before {
        background: #0ea5e9;
      }
    }
  }
}

.filter-by-date {
  display: flex;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  list-style: none;

  // filter-by-date__button
  &__button {
    padding: 6px 12px;
    column-rule: #333;
    background: #faf5ff;
    font-size: 14px;
    color: #a78bfa;
    outline: none;
    font-weight: 500;
    border: none;
    transition: all 0.2s linear;

    &:not(:last-child) {
      border-right: 1px solid #ede9fe;
    }

    &:hover {
      background: #ede9fe;
    }

    &.active {
      background: #8b5cf6;
      color: #fff;
    }
  }
}
</style>

<style lang="scss">
.chart-card__header .el-dropdown-link {
  margin: 0;
  border: none;
}
</style>
