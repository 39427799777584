<template>
  <div>
      <el-select
        v-if="!disab"
        :value="selected"
        @input="dispatch"
        :placeholder="plc || $t('message.warehouse')"
        filterable
        clearable
      >
        <el-option v-for="item in filtered" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>
    <el-select
      v-else
      :value="selected"
      @input="dispatch"
      :placeholder="plc || 'Склад'"
      filterable
      clearable
      disabled
    >
      <el-option v-for="item in filtered" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  mixins: [inventory],
  props: ['warehouse_id', 'mainwarehouse_id'],
  data() {
    return {
        disab: false
    };
  },
  mounted() {
    if (this.warehouses && this.warehouses.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    warehouse_id:{
       handler: function (val, oldVal) { 
          this.selected = val;
          this.$emit("input", this.selected);
       },
       deep: true,
       immediate: true
    }
  },
  computed: {
    ...mapGetters({
      warehouses: 'warehouses/inventory',
    }),
    filtered: function() {
      if (this.mainwarehouse_id) {
        return this.warehouses.filter((o) => {
          return this.mainwarehouse_id != o.id;
        })
      }else{
        return this.warehouses;
      }
    }
  },
  methods: {
    ...mapActions({
      getInventory: 'warehouses/inventory',
    }),
    disableSelect() {
      this.disab = true
    }
  },
};
</script>
