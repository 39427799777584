import { actions } from './pocketsName/actions'
import { getters } from './pocketsName/getters'
import { state } from './pocketsName/state'
import { mutations } from './pocketsName/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
