import { state } from './theme/state'
import { mutations } from './theme/mutations'
import { getters } from './theme/getters'
// import { actions } from './theme/actions'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    // actions
}
