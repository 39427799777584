import request from '../utils/request'


export function index(params) {
  return request({
    url: '/physiotherapies',
    method: 'get',
    params
  })
}
export function inventory(params) {
  return request({
    url: '/physiotherapies/inventory',
    method: 'get',
    params
  })
}

export function showPatient(params) {
  return request({
    url: `/physiotherapies/showPatient`,
    method: 'get',
    params
  })
}

export function countActiveHistory(id) {
  return request({
    url: `/physiotherapies/countActiveHistory/${id}`,
    method: 'get'
  })
}

export function show(params) {
  return request({
    url: `/physiotherapies/${params.hospital_patient_id}`,
    method: 'get',
    params
  })
}
export function showItem(id) {
  return request({
    url: `/physiotherapies/showItem/${id}`,
    method: 'get'
  })
}


export function showNumber(params) {
  return request({
    url: `/physiotherapies/showNumber`,
    method: 'get',
    params
  })
}

export function store(data) {
  return request({
    url: '/physiotherapies',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: `/physiotherapies/${data.id}`,
    method: 'put',
    data
  })
}

export function destroy(id) {
  return request({
    url: `/physiotherapies/${id}`,
    method: 'delete',
  })
}
