<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || $t('message.citizenship')"
    filterable
    clearable
  >
    <el-option
      v-for="item in citizenships"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: ["citizenship_id"],
  mounted() {
    if (this.citizenships && this.citizenships.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    citizenship_id: {
      handler: function (val, oldVal) {
        this.selected = val;
        this.$emit("input", this.selected);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      citizenships: "citizenships/inventory",
    }),
  },
  methods: {
    ...mapActions({
      getInventory: "citizenships/inventory",
    }),
  },
};
</script>
