import request from '../utils/request'

export function index(params) {
  return request({
    url: '/patientBalances',
    method: 'get',
    params
  })
}
export function getRep(params) {
  return request({
    url: '/patientBalance/patientBalanceRep',
    method: 'get',
    params,
    responseType: 'blob'
  })
}
export function indexitems(params) {
  return request({
    url: `/patientBalance/indexitems/${params.hospital_patient_id}`,
    method: 'GET',
    params
  });
}
export function showBalance(params) {
  return request({
    url: `/patientBalance/showBalance/${params.hospital_patient_id}`,
    method: 'GET',
    params
  });
}
export function indexpocket(params) {
  return request({
    url: `/patientBalance/pocketBalance/${params.hospital_patient_id}`,
    method: 'GET',
    params
  });
}
export function indexclasss(params) {
  return request({
    url: `/patientBalance/indexclasss/${params.hospital_patient_id}`,
    method: 'GET',
    params
  });
}
export function indextherapy(params) {
  return request({
    url: `/patientBalance/indextherapy/${params.hospital_patient_id}`,
    method: 'GET',
    params
  });
}
export function inventory(params) {
  return request({
    url: '/patientBalances/inventory',
    method: 'get',
    params
  })
}
export function show(params) {
  return request({
    url: `/patientBalances/${params.hospital_patient_id}`,
    method: 'get',
    params
  })
}
export function showModel(params) {
  return request({
    url: `/patientBalance/showmodel/${params.patient_hitory_id}`,
    method: 'get',
    params
  })
}
export function store(data) {
  return request({
    url: '/patientBalances',
    method: 'post',
    data
  })
}
export function update(data) {
  return request({
    url: `/patientBalances/${data.id}`,
    method: 'put',
    data
  })
}
export function destroy(id) {
  return request({
    url: `/patientBalances/${id}`,
    method: 'delete',
  })
}
export function search(params) {
  return request({
    url: "/patientBalance/search",
    method: "get",
    params,
  });
}
