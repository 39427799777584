import { index, show, store, update, destroy, inventory, showItem, showNumber, countActiveHistory, showPatient } from '../../../api/nurseOffice';

export const actions = {

    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit("SET_LIST", res.data.data.dailyTreatments.data);
                // commit("SET_DATE_LIST", res.data.data.dates);
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.data.dailyTreatments.pagination.total });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params)
                .then(response => {
                    commit('SET_INVENTORY', response.data.data.dailyTreatments.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    show({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            show(params).then(res => {
                commit("SET_LIST", res.data.data.dailyTreatment.data);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    countActiveHistory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            countActiveHistory(params).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    showNumber({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            showNumber(params).then(res => {
                commit("SET_LIST", res.data.data.dailyTreatment.data);
                commit("patientIndicators/SET_LIST", res.data.data.patientIndicator.data, { root: true });
                commit("SET_DATE_LIST", res.data.data.dates);
                commit('patientIndicators/SET_DATE_LIST', res.data.data.datesPatient, { root: true })
                commit("SET_PATIENT_HISTORY_LIST", res.data.data.patientHistory);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    showPatient({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            showPatient(params).then(res => {
                commit("SET_LIST", res.data.data.dailyTreatment.data);
                commit("patientIndicators/SET_LIST", res.data.data.patientIndicator.data, { root: true });
                commit("SET_DATE_LIST", res.data.data.dates);
                commit('patientIndicators/SET_DATE_LIST', res.data.data.datesPatient, { root: true })
                commit("SET_PATIENT_HISTORY_LIST", res.data.data.patientHistory);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    showItem({ commit }, id) {
        return new Promise((resolve, reject) => {
            showItem(id).then(res => {
                commit("SET_MODEL", res.data.data.dailyTreatment);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data).then(res => {
                commit("SET_MODEL", res.data.data.dailyTreatment);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },

    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },

    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },


}
