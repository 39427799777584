import request from './../utils/request'


export function index(params) {
    return request({
        url: '/orders',
        method: 'get',
        params
    })
}

export function inventory(params) {
    return request({
        url: '/orders/inventory',
        method: 'get',
        params
    })
}

export function patientList(params) {
    return request({
        url: '/orders/patient-list',
        method: 'get',
        params
    })
}

export function show(id) {
    return request({
        url: `/orders/${id}`,
        method: 'get'
    })
}

export function miniPrint(params) {
    return request({
        url: `/orders/mini-print`,
        method: 'get',
        params
    })
}

export function print(params) {
    return request({
        url: `/orders/print`,
        method: 'get',
        params
    })
}

export function relation_show(params) {
    return request({
        url: `/orders/${params.id}`,
        method: 'get',
        params
    })
}

export function store(data) {
    return request({
        url: '/orders',
        method: 'post',
        data
    })
}

export function back(data) {
    return request({
        url: `/order/back-to-laboratory/${data.id}`,
        method: 'post',
        data
    })
}

export function update(data) {
    return request({
        url: `/orders/${data.id}`,
        method: 'put',
        data
    })
}

export function updateOrder(data) {
    return request({
        url: `/orders/updateOrder/${data.id}`,
        method: 'put',
        data
    })
}

export function updateStatus(data) {
    return request({
        url: `/order/patient-status/update`,
        method: 'post',
        data
    })
}

export function destroy(id) {
    return request({
        url: `/orders/${id}`,
        method: 'delete',
    })
}

export function chart(params) {
    return request({
        url: '/orders/orders/chart',
        method: 'get',
        params
    })
}

export function chartOrderCount(params) {
    return request({
        url: '/orders/orders/chartOrderCount',
        method: 'get',
        params
    })
}

export function patientMedicine(data) {
    return request({
        url: '/orders/patient-medicine',
        method: 'post',
        data
    })
}

export function patientMedicineShow(data) {
    return request({
        url: '/order-patient/patient-medicine-show',
        method: 'get',
        data
    })
}

export function massOrder(data) {
    return request({
        url: '/massOrder',
        method: 'post',
        data
    })
}

export function expiredService(params) {
    return request({
        url: '/expiredService',
        method: 'get',
        params
    })
}

export function informalPatientList(params) {
    return request({
        url: '/order-patient/informal-patient-list',
        method: 'get',
        params
    })
}

export function updateDoctor(id) {
    return request({
        url: `/order-patient/update-doctor/${id}`,
        method: 'get',
    })
}

export function returnInformalLaborant(id) {
    return request({
        url: `/order-patient/return-informal-laborant/${id}`,
        method: 'get',
    })
}

export function returnInformalDoctor(id) {
    return request({
        url: `/order-patient/return-informal-doctor/${id}`,
        method: 'get',
    })
}

export function updateLaboratory(id) {
    return request({
        url: `/order-patient/update-laboratory/${id}`,
        method: 'get',
    })
}


export function patientLaboratoryList(params) {
    return request({
        url: '/order-patient/patient-laboratory-list',
        method: 'get',
        params
    })
}

export function informalPatientLaboratoryList(params) {
    return request({
        url: '/order-patient/informal-patient-laboratory-list',
        method: 'get',
        params
    })
}

export function orderSericeTerm(params) {
    return request({
        url: '/orderSericeTerm',
        method: 'get',
        params
    })
}

export function orderClose(id) {
    return request({
        url: `/order/close/${id}`,
        method: 'get'
    })
}