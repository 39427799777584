import { sort } from './../properties/sort';
import { pagination } from './../properties/pagination';
import { filter } from './properties/filter';
import { model } from './properties/model';
import { i18n } from '@/utils/i18n';


export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_FILTER: (state, filter) => {
    state.filter = filter;
  },
  SET_SORT: (state, sort) => {
    state.sort = sort;
  },
  SET_MODEL: (state, model) => {
    state.model = model;
    state.model.old_contacts = model.contacts;
    state.model.old_bank_accounts = model.bank_accounts;
    model.contacts = [];
    model.bank_accounts = [];
  },
  SET_INVENTORY: (state, inventory) => {
    state.inventory = inventory;
  },
  UPDATE_COLUMN: (state, column) => {
    state.columns[column.key].show = column.value;
  },
  UPDATE_SORT: (state, sort) => {
    state.sort = sort;
  },
  UPDATE_PAGINATION: (state, pagination) => {
    state.pagination[pagination.key] = pagination.value;
  },
  EMPTY_MODEL: (state) => {
    state.model = JSON.parse(JSON.stringify(model))
  },
  REFRESH: (state) => {
    state.filter = JSON.parse(JSON.stringify(filter));
    // state.sort = JSON.parse(JSON.stringify(sort));
    state.pagination = JSON.parse(JSON.stringify(pagination));
  },
  UPDATE_LANG_COLUMN: (state) => {
      state.columns.id.title = '№';
      state.columns.name.title = i18n.t('message.name');
      state.columns.full_name.title = i18n.t('message.full_name');
      state.columns.code.title = i18n.t('message.code_id');
      state.columns.phone.title = i18n.t('message.phone_number');
      state.columns.fax.title = i18n.t('message.fax');
      state.columns.email.title = i18n.t('message.email');
      state.columns.description.title = i18n.t('message.description');
      state.columns.actual_address.title = i18n.t('message.address');
      state.columns.type.title = i18n.t('message.type');
      state.columns.legal_address.title = i18n.t('message.legal_address');
      state.columns.inn.title = i18n.t('message.inn');
      state.columns.mfo.title = i18n.t('message.mfo');
      state.columns.okonx.title = i18n.t('message.okonx');
      state.columns.oked.title = i18n.t('message.oked');
      state.columns.rkp_nds.title = i18n.t('message.rkp_nds');
      state.columns.created_at.title = i18n.t('message.date');
      state.columns.updated_at.title = i18n.t('message.update_date');
      state.columns.settings.title = i18n.t('message.settings');
      state.columns.bank.bank.title = i18n.t('message.bank');
      state.columns.bank.address.title = i18n.t('message.address');
      state.columns.bank.correspondent_account.title = i18n.t('message.correspondent_account');
      state.columns.bank.checking_account.title = i18n.t('message.accaunt');
      state.columns.contact.full_name.title = i18n.t('message.full_name');
      state.columns.contact.position.title = i18n.t('message.position');
      state.columns.contact.phone.title = i18n.t('message.phone_number');
      state.columns.contact.email.title = i18n.t('message.email');
      state.columns.contact.description.title = i18n.t('message.description');
        
  }
}