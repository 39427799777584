import { i18n } from '@/utils/i18n';
export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    patient_id: {
        show: true,
        title: i18n.t('message.patient'),
        sortable: true,
        column: 'patient_id'
    },
    total_price: {
        show: true,
        title: i18n.t('message.total_amount'),
        sortable: true,
        column: 'total_price'
    },
    order_id: {
        show: true,
        title: i18n.t('message.order_id'),
        sortable: true,
        column: 'order_id'
    },

    service: {
        show: true,
        title: i18n.t('message.service'),
        sortable: true,
        column: 'service'
    },
    paid_price: {
        show: true,
        title: i18n.t('message.paid'),
        sortable: false,
        column: 'paid_price'
    },
    color: {
        show: true,
        title: i18n.t('message.color'),
        sortable: false,
        column: 'color',
        printable: false
    },
    status: {
        show: true,
        title: i18n.t('message.status'),
        sortable: false,
        column: 'status',
        printable: false
    },
    partner_clinic_id: {
        show: true,
        title: i18n.t('message.partner_clinic'),
        sortable: true,
        column: 'partner_clinic_id'
    },
    partner_doctor_id: {
        show: false,
        title: i18n.t('message.partner_doctor'),
        sortable: true,
        column: 'partner_doctor_id'
    },
    doctor_id: {
        show: true,
        title: i18n.t('message.doctor_0'),
        sortable: true,
        column: 'doctor_id'
    },
    laborant: {
        show: false,
        title: i18n.t('message.laborant'),
        sortable: true,
        column: 'laborant'
    },
    created_at: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at',
    },
    user: {
        show: true,
        title: i18n.t('message.user'),
        sortable: true,
        column: 'user',
    },
    // day: {
    //     show: false,
    //     title:  i18n.t('message.day'),
    //     sortable: true,
    //     column: 'day',
    // },
    // notes: {
    //     show: false,
    //     title:  i18n.t('message.notes'),
    //     sortable: true,
    //     column: 'notes',
    // },
    // condition_id: {
    //     show: false,
    //     title:  i18n.t('message.condition'),
    //     sortable: true,
    //     column: 'condition_id',
    // },
    // hour: {
    //     show: false,
    //     title:  i18n.t('message.time'),
    //     sortable: true,
    //     column: 'hour',
    // },
    // yes_doctor: {
    //     show: false,
    //     title:  i18n.t('message.yes_doctor'),
    //     sortable: true,
    //     column: 'yes_doctor',
    // },
    // specialties: {
    //     show: false,
    //     title:  i18n.t('message.specialties'),
    //     sortable: true,
    //     column: 'specialties',
    // },
    // from_hour: {
    //     show: false,
    //     title:  i18n.t('message.from_hour'),
    //     sortable: true,
    //     column: 'from_hour',
    // },
    // duration: {
    //     show: false,
    //     title:  i18n.t('message.duration'),
    //     sortable: true,
    //     column: 'duration',
    // },
    // updated_at: {
    //     show: true,
    //     title: "Изменено",
    //     sortable: true,
    //     column: 'updated_at'
    // },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};