import request from '../utils/request'

export function dashboardInfo() {
  return request({
    url: `/dashboard/dashboardInfo`,
    method: 'get'
  })
}

export function customReport(params) {
  return request({
    url: '/dashboard/report/custom-period',
    method: 'get',
    params
  })
}
