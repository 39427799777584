<template>
  <div class="chart-card">
    <div class="chart-card__header">
      <h2>{{ title }}</h2>
      <div class="md-2 ml-auto">
        <el-date-picker v-model="value1" type="daterange" :start-placeholder="$t('message.start_period')"
                        :end-placeholder="$t('message.end_period')" v-if="selected_item === 'period'">
        </el-date-picker>
      </div>
      <div class="md-6 ml-auto">
        <el-dropdown>
          <span class="el-dropdown-link">
            {{ $t(`message.${selected_item}`) }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in dateItems" @click.native="selected_item = item" :key="item">{{
                $t(`message.${item}`) }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="md-3">
        <el-button
            size="mini"
            class="btn excel_btn"
            @click="printExcel"
            icon="el-icon-document-delete"
            :loading="loadingButton"
            :disabled="loadingButton ? true : false"
        ></el-button>
      </div>
    </div>

    <div class="px-3">
      <div class="tableFixHead">
        <table class="table table-hover" v-loading="false">
          <thead>
          <th>
            Врач
          </th>

          <th>
            Консультации
          </th>
          <th>
            Стационар
          </th>
          <th>
            Дневной стационар
          </th>
          <th>
            Сумма
          </th>
          </thead>
          <tbody>
            <tr v-for="(data, doctorId) in reports[selected_item]" :key="doctorId">
              <td class="d-flex align-items-center">
                <el-avatar size="medium" :src="`/img/doctor_icons/2.png`"></el-avatar>
                <p class="m-0 pl-3">{{ data.fullname }}</p>
              </td>
              <td>{{ data.statistic.consultations_count | formatMoney }}</td>
              <td>{{ data.statistic.hospital_count | formatMoney }}</td>
              <td>{{ data.statistic.day_hospital_count | formatMoney }}</td>
              <td>
                <p class="m-0">{{ totalIncome(data) | formatMoney }} сум</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {formatMoney} from "@/filters"

export default {
  props: ['title', 'reports'],
  data() {
    return {
      show: false,
      selected_item: "daily",
      value1: null,
      dateItems: [
        "daily",
        "weekly",
        "monthly",
        "quarterly",
        "half_yearly",
        "yearly",
        "period"
      ],
      backendUrl: process.env.VUE_APP_URL,
      custom_period_value: null,
      loadingButton: false,
    }
  },
  computed: {
    ...mapGetters({
      mode: 'theme/MODE',
      token: 'auth/token',
    }),
    totalIncome() {
      return function (data) {
        return data.statistic.consultations_income + data.statistic.hospital_income + data.statistic.day_hospital_income;
      }
    }
  },
  watch: {
    data: function (val) {
      this.setData(val);
    },
    value1: function (val) {
      if (val) {
        this.custom_period_value = val;
        this.selected_item = "period";
        this.fetchData();
      }
    },
    selected_item: function (val) {
      if (val === 'period') {
        this.value1 = this.custom_period_value;
      }
    },
  },
  methods: {
    ...mapActions({
      excelMedicsReport: "orders/excelMedicsReport",
      period: "dashboards/customReport"
    }),
    formatMoney,
    async fetchData() {
       await this.period({
          start_date: this.value1[0],
          end_date: this.value1[1],
          report: 'doctors',
        })
        .then((res) => {
          this.$set(this.reports, 'period', res.data);
        })
        .catch ((error) => {
          console.error('Ошибка при выполнении запроса:', error);
        })
    },
    setData(val) {
      this.show = false;
    },
    printExcel() {
      this.loadingButton = true;
      this.excelMedicsReport([this.value1, this.selected_item])
        .then((res) => {
          this.loadingButton = false;
          const url = window.URL.createObjectURL(
              new Blob([res], {
                type: "application/vnd.ms-excel",
              })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Отчёт по врачам.xlsx");
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url); // Освобождение ресурсов
        })
        .catch((err) => {
          this.loadingButton = false;
          err.text().then((text) => {
            let error = JSON.parse(text);
            if (error.code == 422) {
              this.$notify({
                title: this.$t("message.validation"),
                type: "error",
                position: "bottom right",
                message: error.error.message,
              });
            } else {
              this.$alert(text);
            }
          });
        });
    },
  },
}
</script>

<style lang="scss" scoped>
body.dark-mode th {
  background: #3e4166
}

.tableFixHead {
  overflow-y: auto;
  height: 300px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th {
  background: #eee;
}

th,
td,
table {
  font-size: 14px;
  border: none !important;
  color: #444;

}

thead {
  border-bottom: 1px #44444417 solid;
}

td {
  p {
    font-weight: 600;
  }
}
</style>