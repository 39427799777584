<template>
  <div class="chart-card">
    <div class="chart-card__header">
      <h2>{{ $t("message.top_services") }}</h2>
      <!-- <el-dropdown>
        <span class="el-dropdown-link">
          View All<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>Action 1</el-dropdown-item>
          <el-dropdown-item>Action 2</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>

    <div>
      <div>
        <div class="total-wrap" v-if="top_services.length">
          <h3 class="total-wrap__total">
            {{ top_services[0].count | formatMoney }} 
          </h3>
          <!-- <span class="total-wrap__percentage" :class="{ loss: false }">
            1.02%
            <i
              :class="[false ? 'fa-caret-down' : 'fa-caret-up', 'fa-solid']"
            ></i>
          </span> -->
          <span class="total-wrap__text">
            {{ top_services[0].service.name }}
          </span>
        </div>
        <apexchart ref="chart" type="bar" :height="52" :options="chartOptions" :series="series"></apexchart>
      </div>
      <!-- status list -->
      <ul class="status-list">
        <li class="status-list__item" v-for="(item, index) in statusList" :key="index">
          <span>{{ item.title }}</span>
          {{ item.total | formatMoney }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["top_services"],
  data() {
    return {
      statusList: [],
      show: true,
      series: [],
      chartOptions: {
        dataLabels: { enabled: true },
        theme: {
          mode: 'light'
        },
        chart: {
          background: 'transparent',
          height: "auto",
          type: "bar",
          stacked: true,
          stackType: "100%",
          offsetX: -10,
          offsetY: 0,
          toolbar: {
            show: false,
          },
        },
        colors: ["#a855f7", "#0ea5e9", "#fbbf24", "#f271b5", "#51bc92", "#80fa0b", "#d83131", "#2342be", "#fdcf36", "#22c45e"],
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 4,
            borderRadiusApplication: "around",
            borderRadiusWhenStacked: "all",
            barHeight: "100%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 0,
          colors: ["#f00"],
        },
        xaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          axisBorder: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        tooltip: {
          formatter: function (val) {
            return val + "K";
          },
        },
        legend: {
          show: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      mode: 'theme/MODE'
    })
  },
  watch: {
    top_services: function (val) {
      this.setData(val);
    },
    mode(bool) {
      this.updateChart(bool)
    }
  },
  mounted() {
    this.updateChart(this.mode)
  },
  methods: {
    setData(val) {
      this.show = false;
      this.series = [];
      this.statusList = [];
      val.forEach((element) => {
        this.series.push({
          name: element.service.name,
          data: [element.count],
        });
        this.statusList.push({
          title: element.service.name,
          total: element.count,
        });
      });

      this.show = true;
    },
    updateChart(bool) {
      this.$refs.chart.updateOptions({
        theme: {
          mode: bool ? 'dark' : 'light'
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.total-wrap {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 12px 0;

  // total-wrap__total
  &__total {
    font-size: 24px;
    font-weight: 700;
    color: #222;
    margin: 0;
    // flex-shrink: 0;
  }

  // total-wrap__percentage
  &__percentage {
    display: inline-block;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #22c55e;
    background: #ecfdf5;

    &.loss {
      color: #ef4444;
      background: #fef2f2;
    }

    i {
      margin-left: 4px;
    }
  }

  // total-wrap__text
  &__text {
    color: #9ca3af;
  }
}

.status-list {
  margin: 0;
  padding: 0 12px 12px;
  list-style: none;

  // status-list__item
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-size: 400;
    color: #a1a1aa;
    padding-left: 18px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    span {
      color: #333;
      font-size: 14px;
    }

    &::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background: #000000;
    }

    &:first-child::before {
      background: #a855f7;
    }

    &:nth-child(2)::before {
      background: #0ea5e9;
    }

    &:nth-child(3)::before {
      background: #fbbf24;
    }

    &:nth-child(4)::before {
      background: #f472b6;
    }

    &:nth-child(5)::before {
      background: #51bd93;
    }

    &:nth-child(6)::before {
      background: #81fc0b;
    }

    &:nth-child(7)::before {
      background: #d93131;
    }

    &:nth-child(8)::before {
      background: #2342be;
    }

    &:nth-child(9)::before {
      background: #fdcf36;
    }

    &:last-child::before {
      background: #22c55e;
    }
  }
}
</style>