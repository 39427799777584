import Layout from '@/layouts'

const reportCategory = {
    path: '/report-category',
    component: Layout,
    name: 'reportCategory',
    redirect: '/report-category/index',
    children: [
        {
            path: '/report-category/index',
            component: () => import('@/views/reportCategory/index'),
            name: 'reportCategory.index',
            meta: { title: 'report Category' },
        },
        {
            path: "",
            component: () => import("@/views/reportCategory/_slug"),
            name: "reportCategory.slug",
            meta: { title: "slug" },
            children: [
                {
                    path: '/report-category/report/report-doctors',
                    component: () => import('@/views/report/report-doctors'),
                    name: 'reportDoctors/index',
                    meta: { title: 'Типы расхода' }
                },
                {
                    path: '/report-category/partnerDoctorReport/index',
                    component: () => import('@/views/partnerDoctorReport/index'),
                    name: 'partnerDoctorReport.index',
                    meta: { title: 'Партнер доктор' }
                },
                {
                    path: '/report-category/partnerClinicReport/index',
                    component: () => import('@/views/partnerClinicReport/index'),
                    name: 'partnerClinicReport.index',
                    meta: { title: 'Партнер доктор' }
                },
                {
                    path: '/report-category/paymentReport/index',
                    component: () => import('@/views/paymentReport/index'),
                    name: 'paymentReport.index',
                    meta: { title: 'Тип оплаты' }
                },
                {
                    path: '/report-category/patientResidenceReport/index',
                    component: () => import('@/views/patientResidenceReport/index'),
                    name: 'patientResidenceReport.index',
                    meta: {
                        title: 'График'
                    }
                },
                {
                    path: '/report-category/patientServiceReport/index',
                    component: () => import('@/views/patientServiceReport/index'),
                    name: 'patientServiceReport.index',
                    meta: {
                        title: 'График'
                    }
                },
                {
                    path: '/report-category/dailyProcessReport/index',
                    component: () => import('@/views/dailyProcessReport/index'),
                    name: 'dailyProcessReport.index',
                    meta: {
                        title: 'Отчет'
                    }
                },
                {
                    path: '/report-category/doctorProcessReport/index',
                    component: () => import('@/views/doctorProcessReport/index'),
                    name: 'doctorProcessReport.index',
                    meta: {
                        title: 'Отчет'
                    }
                },
                {
                    path: '/report-category/excelReports/index',
                    component: () => import('@/views/excelReport/index'),
                    name: 'excelReports.index',
                    meta: { title: 'Тип документа' }
                },
            ]
        }
    ]
}
export default reportCategory