export const getters = {
    list: state => state.list,
    model: state => state.model,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    excel_columns: state => state.excel_columns,
    pagination: state => state.pagination,
    sort: state => state.sort,
    permissions: state => state.permissions
};
