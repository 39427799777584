export const mutations = {
  SET_VERSION(state, payload) {
    console.log('SET_VERSION', typeof payload, payload);
    if (payload === 0 || payload === 1) {
      state.version = payload
      localStorage.setItem("version", payload)
    }
  },
  SET_THEME(state, payload) {
    if (payload && payload.id) {
      state.theme = payload
      localStorage.setItem("theme", JSON.stringify(payload))
    }
  }
}