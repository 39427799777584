<template>
  <div class="top-collectors chart-card">
    <div class="top-collectors__header chart-card__header">
      <div class="mr-auto">
        <h2>{{ title }}</h2>
      </div>
      <div class="md-1 ml-auto">
        <el-dropdown>
          <span class="el-dropdown-link">
            {{ $t(`message.${selected_item}`)
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in dateItems" @click.native="selected_item = item" :key="item">{{
                $t(`message.${item}`) }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="md-1">
        <el-button
            size="mini"
            class="btn excel_btn"
            @click="printExcel"
            icon="el-icon-document-delete"
            :loading="loadingButton"
            :disabled="loadingButton ? true : false"
        ></el-button>
      </div>
    </div>

    <div class="center">
      <el-date-picker v-model="value1" type="daterange" :start-placeholder="$t('message.start_period')"
                      :end-placeholder="$t('message.end_period')" v-if="selected_item === 'period'">
      </el-date-picker>
    </div>

    <div class="clinics-report">
      <div v-for="(value, index) in reports[selected_item]" :key="index">
        <el-row class="p-4 d-flex">
          <el-col class="pr-2 col-md-6">
            <h6 v-if="checkType(index)">{{ $t(`message.` + index) }}</h6>
            <h6 v-else>{{ index }}</h6>
            <span>{{ value.count | formatMoney }}</span>
          </el-col>
          <el-col class="pl-2 col-md-6">
            <h6 v-if="checkType(index)">{{ $t(`message.` + index + `_income`) }}</h6>
            <h6 v-else>{{ index + ' ' + $t('message.summ') }}</h6>
            <span>{{ value.income | formatMoney }} cум</span>
          </el-col>
        </el-row>
      </div>
      <el-row class="p-4 d-flex">
        <el-col class="pr-2">
          <h6>{{ $t(`message.totaly`) }}</h6>
        </el-col>
        <el-col class="pl-2">
          <span>{{ reports[selected_item].patients.income | formatMoney }} сум</span>
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
import { formatMoney } from "@/filters"
import axios from "axios";
import {mapGetters, mapActions} from "vuex";

export default {
  props: ["title", "reports"],
  computed: {
    ...mapGetters({
      token: 'auth/token',
    }),
    totalIncome() {
      const selectedReport = this.reports[this.selected_item]

      // Суммируем все инкомы
      // Возвращаем сумму
      return (
        selectedReport.patients_income +
        selectedReport.hospital_income +
        selectedReport.day_hospital_income +
        selectedReport.consultation_services_income +
        selectedReport.another_services_income
      )
    }
  },
  data() {
    return {
      selected_item: "daily",
      value1: '',
      backend_url: process.env.VUE_APP_URL,
      custom_period_value: '',
      dateItems: [
        "daily",
        "weekly",
        "monthly",
        "quarterly",
        "half_yearly",
        "yearly",
        "period"
      ],
      loadingButton: false,
    }
  },
  methods: {
    ...mapActions({
      excelClinicsReport: "orders/excelClinicsReport",
      period: "dashboards/customReport"
    }),
    formatMoney,
    userName(item) {
      if (item.user) return item.user.name + " " + item.user.surname
      if (item.partner_doctor) return item.partner_doctor.name
      return ""
    },
    percentage(item) {
      return parseFloat(
        ((item.bonus_amount * 100) / this.doctor.sum_doctor_bonus).toFixed(2)
      )
    },
    checkType(text){
      const englishRange = [97, 122];
      const hasEnglish = text
          .toLowerCase()
          .split('')
          .some(char => {
            const charCode = char.charCodeAt(0);
            return charCode >= englishRange[0] && charCode <= englishRange[1];
          });

      return  hasEnglish;
    },
    async fetchData() {
      await this.period({
        start_date: this.value1[0],
        end_date: this.value1[1],
        report: 'clinic',
      })
          .then((res) => {
            console.log(res.data)
            this.$set(this.reports, 'period', res.data);
          })
          .catch ((error) => {
            console.error('Ошибка при выполнении запроса:', error);
          })
    },
    printExcel() {
      this.loadingButton = true;
      this.excelClinicsReport([this.value1, this.selected_item])
        .then((res) => {
          this.loadingButton = false;
          const url = window.URL.createObjectURL(
              new Blob([res], {
                type: "application/vnd.ms-excel",
              })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Отчёт по клинике.xlsx");
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.loadingButton = false;
          err.text().then((text) => {
            let error = JSON.parse(text);
            if (error.code == 422) {
              this.$notify({
                title: this.$t("message.validation"),
                type: "error",
                position: "bottom right",
                message: error.error.message,
              });
            } else {
              this.$alert(text);
            }
          });
        });
    },
  },
  watch: {
    value1: function (val) {
      if (val) {
        this.selected_item = 'period';
        this.custom_period_value = val;
        this.fetchData()
      }
    },
    selected_item: function (val) {
      this.selected_item = val;
      console.log(this.reports[this.selected_item])
      if (this.selected_item === 'period') {
        this.value1 = this.custom_period_value;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.chart-card {
  height: 100%;
}

.clinics-report {
  position: relative;

  // &::after {
  //   position: absolute;
  //   content: '';
  //   width: 1px;
  //   height: 100%;
  //   background: #00000018;
  //   left: 47%;
  //   top: 0;
  //   transform: translateX(-50%);
  // }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.clinics-report {
  h6 {
    margin: 0;
    color: #222;
  }

  span {
    color: #10b981;
    font-size: 14px;
  }

  .el-row {
    border-bottom: 1px solid #eee;
  }
}
</style>
