<template>
  <div id="app" :style="{ backgroundImage: `url(${imgUrl})` }" :class="{'block-scroll': mobileMenu}">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState({
      version: (state) => state.theme.version,
      theme: (state) => state.theme.theme
    }),
    ...mapGetters({
      mode: "theme/MODE",
      mobileMenu: 'mobileMenu',
    }),
    imgUrl() {
      return +this.version
        ? require(`./static/background/${this.theme.url}`)
        : ""
    },
  },

  watch: {
    version(v) {
      this.setSettings(v)
    },
  },

  beforeMount() {
    const v = localStorage.getItem("version")
    const theme = JSON.parse(localStorage.getItem("theme"))

    if (v != null && v !== undefined) {
      this.$store.commit("theme/SET_VERSION", v)
    }

    if (theme && theme.id) {
      this.$store.commit("theme/SET_THEME", theme)
    }
  },

  mounted() {
    this.setSettings(this.version)
  },

  methods: {
    setSettings(version) {
      // version
      if (version) {
        document.body.classList.add("new-version")
      } else {
        document.body.classList.remove("dark-mode")
        document.body.classList.add("old-version")
        document.body.classList.remove("new-version")
      }
      // mode
      if (version && this.mode) {
        document.body.classList.add("dark-mode")
      } else {
        document.body.classList.remove("dark-mode")
      }
    },
  },
}
</script>

<style lang="scss">
#mi {
  background-image: url("https://picsum.photos/id/29/1200/950");
  background-size: cover;
  border: 1px solid red;
  width: 500px;
  height: 450px;
  overflow: hidden;
}

#app {
  min-height: 100vh;
  position: relative;
  background-color: #f2f2f2 !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #fff, $alpha: 0.05);
    backdrop-filter: blur(5px);
  }
}

.my-tree-khan {
  white-space: break-spaces !important;
  font-size: 14px;
  line-height: 14px;
}

.el-icon--left {
  margin: 0 !important;
}
</style>

<!-- NEW VERSION -->
<style lang="scss">
.new-version {
  .__header {
    background: #fff;
    box-shadow: 100px 4px 33px #ccc;
  }

  .navberRight {
    .el-button {
      width: 38px;
      height: 38px;
      padding: 0 !important;
      background: #fff;
      border: 1px solid #e8e5dd;

      span {
        opacity: 0.5;
        font-size: 20px;
      }

      &:hover {
        color: #fff;
        background-color: #0c67c9;
        border-color: #0c67c9;

        span {
          opacity: 1;
        }
      }
    }
  }

  // all headers
  .crm-schedule-header,
  .modal_header,
  .el-drawer__header {
    background-image: linear-gradient(
      to right,
      #0c67c9,
      #0080d0,
      #0090ab,
      #009869,
      #399919
    ) !important;

    .title_h,
    & > span {
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
    }
  }
}
</style>

<!--  DARK MODE -->
<style lang="scss">
.vue-phone-number-input input {
  outline: none;
}

table {
  background: #fff;
}

.dark-mode {
  .__header {
    background-color: #151e346b;
    border-color: transparent;
    box-shadow: none;
    backdrop-filter: blur(5px);
  }

  .main-wrapper__menu .aside {
    background-color: #151e346b;
    // backdrop-filter: blur(5px);

    .sub-menu {
      background-color: #151e346b !important;
      box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.2);

      // sub-menu-list__item
      &-list__item,
      &-list__item i {
        color: #fff;
        opacity: 0.7;

        &:hover,
        &.router-link-exact-active {
          background: #2a2a3f63 !important;
          opacity: 1;
        }
      }
    }
  }

  // .el-button {}
  table thead,
  table tbody,
  .el-table .el-table__header,
  .el-table .el-table__body,
  .el-drawer__wrapper .el-drawer .el-card .el-card__body table thead,
  .el-drawer__wrapper .el-drawer .el-card .el-card__body table tbody,
  .el-table tr,
  .el-table th.el-table__cell {
    background: #1a2540ed !important;

    tr th,
    tr td {
      color: #fff;
      border-color: #3f4d7087;
    }
  }

  .el-table--border {
    border-color: #3f4d7087;
  }

  .chart-wrap,
  .list__item-wrap > div {
    background: #475474 !important;
  }

  .el-divider .el-divider__text {
    background: #eef2ff;
  }

  .el-table__empty-block,
  .chart-card,
  .balans-card {
    background: #1a2540ed !important;

    *,
    span,
    .balans-card__total,
    .statistic__item .count,
    table tr td div > h6 {
      color: #fff !important;
    }
  }

  // all button
  button,
  .el-button,
  .avatar,
  .plusic,
  .el-dropdown-link {
    background: #1a2540ed !important;
    border: 1px solid #323248 !important;
    color: #fff !important;

    &:hover {
      border-color: #4580eb !important;
      background-color: #4580eb !important;
    }
  }

  .itme_blocks_pages,
  .home_link-pages,
  .mychart.bg-white {
    border: 1px dashed #151e346b !important;
    background: #151e346b !important;

    &_botton_summa,
    & .text_icon span,
    & .chrttext {
      color: #fff !important;
    }

    &:hover {
      border-color: #00b2ff;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
  }

  .bd-highlight .el-radio-group {
    .el-radio-button__inner {
      user-select: none;
      background-color: #30304bc7;
      border: 1px solid #30304bc7;
      color: #fff;
    }

    .el-radio-button.is-active {
      .el-radio-button__inner {
        background-color: #61617ac7 !important;
      }
    }
  }

  .el-input {
    &__inner {
      background-color: #303352 !important;
      border: 1px solid #151e346b !important;
      color: #fff !important;

      &:hover,
      &:focus {
        background-color: #3e4166 !important;
        border-color: #3e4166 !important;
      }
    }
  }

  .vue-phone-number-input {
    input {
      background-color: #303352;
      color: #fff;

      &::placeholder {
        color: #9ca3af;
      }
    }

    label {
      color: #fff;
    }
  }

  .el-form-item__label,
  .costum-radio__label,
  .costum-radio-label,
  .theme__title {
    color: #fff;
  }

  .textarea,
  .el-textarea__inner {
    background-color: #303352 !important;
    border: 1px solid #151e346b !important;
    color: #fff !important;

    &:hover,
    &:focus {
      background-color: #3e4166 !important;
      border-color: #3e4166 !important;
    }
  }

  .app-main div h5,
  .app-main div h5 + i,
  .app-main > div > div > div > h5,
  .app-main > div > div > div > h5 + i,
  .app-main > div > div > div > div > h5,
  .app-main > div > div > div > div > h5 + i,
  .sub-menu .sub-menu__title,
  .el-card__header .clearfix,
  .link_blocks a,
  .el-breadcrumb .el-breadcrumb__inner {
    color: #fff;
  }

  .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: #cbd5e1;
  }

  .el-select-dropdown,
  .el-picker-panel,
  .el-scrollbar__view {
    background: #3e4166;

    .popper__arrow::after {
      border-bottom-color: #334155 !important;
    }

    .el-date-picker__header-label,
    .el-select-dropdown__empty {
      color: #fff;
    }

    .el-select-dropdown__item {
      background: #30304bc7;
      color: #fff;

      &:hover {
        background-color: #3e4166 !important;
        border-color: #3e4166 !important;
      }
    }

    .el-picker-panel__icon-btn {
      background: transparent !important;
    }

    table,
    table thead,
    table tbody,
    .el-table,
    .el-table .el-table__header,
    .el-table .el-table__body {
      background: transparent !important;
    }
  }

  .el-card {
    background-color: #1a2540ed !important;
    border-color: #3f4d7087 !important;
    color: #fff;

    // .el-card__header
    &__header {
      border-color: #44587c75;
    }

    .settings-list__item {
      background: #2a2a3fa6;
      border-color: #44587c75;

      &:hover,
      &:hover i {
        color: #fff;
        border-color: #fff;
      }
    }

    .popper__arrow::after {
      border-bottom-color: #1a2540ed !important;
    }
  }

  // BG
  .patientRoom_tabs,
  .el-message-box,
  .list__item-wrap div .list__item,
  .statistic-info-wrap .list__item {
    background: #1a2540ed;
  }

  .el-message-box *,
  .list__item-wrap div .list__item *,
  .statistic-info-wrap .list__item .list__item-total {
    color: #fff;
  }

  table,
  .app-main .table-bordered {
    border: none !important;
  }

  table thead th,
  .table thead th,
  table tbody td {
    color: #fff;
  }

  * table tbody tr:hover,
  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background: rgba(0, 0, 0, 0.075) !important;
  }

  .el-drawer__wrapper .el-drawer,
  .blocks__hostipil,
  .scheduler > .filter,
  .scheduler > .filter .el-calendar__header {
    background-color: #1a2540ed !important;
  }

  .scheduler > .filter .el-calendar__body table {
    border-collapse: unset;
  }

  .el-calendar-table tr td:first-child,
  .el-calendar-table tr:first-child td {
    border: none;
  }

  .el-calendar-table thead th {
    color: #d4d4d8;
  }

  table tbody .el-calendar-day:hover {
    background: #a1a1aa;
  }

  .blocks__hostipil {
    border-color: #3e4166;
  }

  .blocks__hostipil *,
  .scheduler > .filter .el-calendar__header *,
  .scheduler .schedule .media .media-body p,
  .el-dropdown-menu.el-popper.costum-menu * {
    color: #fff;
  }

  // hover
  .el-dropdown-menu.el-popper.costum-menu li:hover,
  .el-tree-node__content:hover {
    background: #475569;
  }

  .el-tree-node__content:hover * {
    color: #fff;
  }

  .scheduler .schedule .media .media-body p + div {
    color: #94a3b8;
  }

  table tbody tr td .comPatientModal .pustoy,
  .el-dropdown-menu.el-popper.costum-menu,
  .el-tree {
    background: #334155;
  }

  .app-main .card-box {
    background-color: #1a2540ed !important;
    border-color: #3f4d7087 !important;
  }

  .el-divider {
    background: #94a3b8;

    &__text {
      padding: 5px;
      color: #fff !important;
      min-width: 120px;
      background: #f1f5f9;
    }
  }

  // card block
  .el-tabs,
  .el-tabs .el-tabs__header {
    background: #1a2540ed;
  }

  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    background: #2b3f71ed;
    color: #fff;
  }

  // all elements
  * {
    border-color: #3e4166 !important;

    &::-webkit-scrollbar-track {
      background-color: #1f2937;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #1f2937;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: #374151;
      border-color: #374151;
    }
  }
}

* {
  // scrool bar style
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #ebeef5;
    border: 2px solid #fff0;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: #d1d7e4;
    border-color: #d1d5db;
  }
}
</style>

<!-- 
======================================================================================
************* GENERAL STYLES *************
======================================================================================
-->
<style lang="scss">
// navbar
.tabs-navbar {
  background-color: transparent;
  padding: 4px 0;
  border-bottom: 1px solid #ddd;
  margin: 0 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6rem;

  // tabs-navbar-list
  &-list {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-x: auto;
    white-space: nowrap;

    // scrool bar style
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    // tabs-navbar-list__item
    &__item {
      position: relative;
      z-index: 1;
      transition: all 0.2s ease-in-out;

      &:hover a {
        color: #fff;
        background-color: #0c67c9;
      }

      // tabs-navbar-list__item-link
      &-link {
        display: block;
        color: #727b89;
        font-size: 1rem;
        font-weight: 500;
        text-decoration: none !important;
        border-radius: 5px;
        padding: 4px 10px;
        transition: all 0.2s ease-in-out;

        &.router-link-exact-active {
          color: #fff;
          background-color: #0c67c9;
        }
      }
    }
  }

  // tabs-navbar__buttons
  &__buttons {
    flex-shrink: 0;
  }

  // dark mode
  &.dark {
    border-color: #44587c75;

    .el-button {
      color: #fff;
      background-color: #44587c75;
      border-color: #44587c75;
    }

    // tabs-navbar-list
    .tabs-navbar-list__item {
      color: #fff;
    }
  }
}
</style>
