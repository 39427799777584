import {
  sort
} from './../properties/sort';
import {
  pagination
} from './../properties/pagination';
import {
  filter
} from './properties/filter';
import {
  model
} from './properties/model';
import { i18n } from '@/utils/i18n';


export const mutations = {
  SET_LIST: (state, suppliers) => {
    state.list = suppliers;
  },
  SET_FILTER: (state, filter) => {
    state.filter = filter;
  },
  SET_SORT: (state, sort) => {
    state.sort = sort;
  },
  SET_MODEL: (state, model) => {
    state.model = model;
    state.model.category_id = model.category ? model.category.id : "";
    state.model.currency_id = model.currency ? model.currency.id : "";
    state.model.measurement_id = model.main_measurement ? model.main_measurement.id : "";
    if (state.model.secondary_measurements) {
      state.model.secondary_measurements = state.model.secondary_measurements.map(element => {
        return element.pivot;
      });
    }
    state.model.old_secondary_measurements = [];
    state.model.new_secondary_measurements = [];
  },
  SET_INVENTORY: (state, inventory) => {
    state.inventory = inventory;
  },
  UPDATE_COLUMN: (state, column) => {
    state.columns[column.key].show = column.value;
  },
  UPDATE_SORT: (state, sort) => {
    state.sort = sort;
  },
  UPDATE_PAGINATION: (state, pagination) => {
    state.pagination = pagination;
  },
  EMPTY_MODEL: (state) => {
    state.model = JSON.parse(JSON.stringify(model))
  },
  REFRESH: (state) => {
    state.filter = JSON.parse(JSON.stringify(filter));
    // state.sort = JSON.parse(JSON.stringify(sort));
    state.pagination = JSON.parse(JSON.stringify(pagination));
  },
  UPDATE_LANG_COLUMN: (state) => {
      state.columns.id.title= '№';
      state.columns.name.title= i18n.t('message.name');
      state.columns.code.title= i18n.t('message.code');
      state.columns.sku.title= i18n.t('message.sku');
      state.columns.price.title= i18n.t('message.price2');
      state.columns.currency.title= i18n.t('message.currency');
      state.columns.category.title= i18n.t('message.group');
      state.columns.vat.title= i18n.t('message.nds');
      state.columns.description.title=  i18n.t('message.description');
      state.columns.measurement.title=  i18n.t('message.measurement_full');
      state.columns.created_at.title= i18n.t('message.date');
      state.columns.updated_at.title= i18n.t('message.update_date');
      state.columns.settings.title= i18n.t('message.settings');

  }
}