import request from "./../utils/request";

export function excelReport(params) {
    return request({
        url: "/excel-report/excel-report",
        method: "get",
        responseType: 'blob',
        params
    });
}

export function excelReport2(params) {
    return request({
        url: "/excel-report/excel-report2",
        method: "get",
        responseType: 'blob',
        params
    });
}

export function excelReport3(params) {
    return request({
        url: "/excel-report/excel-report3",
        method: "get",
        responseType: 'blob',
        params
    });
}

export function excelReport4(params) {
    return request({
        url: "/excel-report/excel-report4",
        method: "get",
        responseType: 'blob',
        params
    });
}

export function excelReport5(params) {
    return request({
        url: "/excel-report/excel-report5",
        method: "get",
        responseType: 'blob',
        params
    });
}

export function excelReport6(params) {
    return request({
        url: "/excel-report/excel-report6",
        method: "get",
        responseType: 'blob',
        params
    });
}

export function excelMedicsReport(params) {
    return request({
        url: "/excel-report/excel-medics-report",
        method: "get",
        responseType: 'blob',
        params
    });
}

export function excelClinicsReport(params) {
    return request({
        url: "/excel-report/excel-clinics-report",
        method: "get",
        responseType: 'blob',
        params
    });
}