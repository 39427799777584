import request from '../utils/request'


export function index(params) {
  return request({
    url: '/payment_bonus',
    method: 'get',
    params
  })
}

export function returnBack(id) {
  return request({
    url: `/payment_bonus/returnBack/${id}`,
    method: 'GET',
  });
}

export function inventory(params) {
  return request({
    url: '/payment_bonus/inventory',
    method: 'get',
    params
  })
}

export function massPaid(data) {
  return request({
    url: '/payment_bonus/mass_paid',
    method: 'post',
    data
  })
}

export function show(id) {
  return request({
    url: `/payment_bonus/${id}`,
    method: 'get'
  })
}

export function store(data) {
  return request({
    url: '/payment_bonus',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: `/payment_bonus/${data.id}`,
    method: 'put',
    data
  })
}

export function destroy(id) {
  return request({
    url: `/payment_bonus/${id}`,
    method: 'delete',
  })
}

export function getPartDoctor(params) {
  return request({
    url: `/payment_bonus/get_part_doctors`,
    method: 'get',
    params
  })
}

export function getBonusDebt(params) {
  return request({
    url: `/payment_bonus/get_bonus_debt`,
    method: 'get',
    params
  })
}