import request from './../utils/request'

export function index(params) {
  return request({
    url: '/excel-report/indexReport',
    method: 'get',
    params
  })
}

export function show(id) {
  return request({
    url: `/excel-report/showReport/${id}`,
    method: 'get'
  })
}

export function store(data) {
  return request({
    url: '/excel-report/storeReport',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: `/excel-report/updateReport/${data.id}`,
    method: 'put',
    data
  })
}

export function destroy(id) {
  return request({
    url: `/excel-report/deleteReport/${id}`,
    method: 'delete',
  })
}

export function getColumns(params) {
  return request({
    url: '/excel-report/getExcelReportColumns',
    method: 'get',
    params
  })
}

export function report(params) {
  return request({
    url: `/excel-report/report/${params.id}`,
    method: 'get',
    responseType: 'blob',
    params
  })
}