<template>
  <div class="chart-card">
    <div class="chart-card__header">
      <h2>{{ title }}</h2>
      <div>
        <el-date-picker v-model="value1" type="daterange" :start-placeholder="$t('message.start_period')"
                        :end-placeholder="$t('message.end_period')" v-if="selected_item === 'period'">
        </el-date-picker>
      </div>
      <el-dropdown>
        <span class="el-dropdown-link">
          {{ $t(`message.${selected_item}`)
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item in dateItems"
            @click.native="selected_item = item"
            :key="item"
            >{{ $t(`message.${item}`) }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="chart-card__statistic">
      <div
        class="chart-card__statistic-item"
        v-for="(item, index) in statistic"
        :key="index"
      >
        <h3 class="count">{{ item.count }}</h3>
        <p class="subtitle">{{ item.subtitle }}</p>
      </div>
    </div>
    <div>
      <apexchart
        type="area"
        ref="chart"
        height="300"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import {toInteger} from "lodash";
export default {
  props: ["chart", "title", "spec"],
  data() {
    return {
      statistic: [],
      series: [],
      selected_item: "daily",
      value1: null,
      dateItems: [
        "daily",
        "weekly",
        "monthly",
        "quarterly",
        "half_yearly",
        "yearly",
        "period",
      ],
      backendUrl: process.env.VUE_APP_URL,
      custom_period_value: null,
      chartOptions: {
        theme: {
          mode: "light",
        },
        chart: {
          background: "transparent",
          height: 350,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "top",
        },
        stroke: {
          width: 2,
          curve: "smooth",
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
        colors: ["#e6dd63", "#0ea5e9","#7feb71","#e20aff"],
      },
    };
  },
  watch: {
    chart: function (val) {
      this.setData(val, this.selected_item);
    },
    value1: function (val) {
      if (val) {
        this.custom_period_value = val;
        this.selected_item = "period";
        this.fetchData();
      }
    },
    selected_item: function (val) {
      if (val === 'period') {
        this.value1 = this.custom_period_value;
      }
      this.setData(this.chart, val);
    },
    mode(bool) {
      this.updateChart(bool);
    },
  },
  mounted() {
    this.updateChart(this.mode);
  },
  methods: {
    ...mapActions({
      period: "dashboards/customReport"
    }),
    setData(val, selected_item) {
      const chart = this.$refs.chart;
      this.chartOptions.xaxis.categories = [];
      this.series = [];
      for (const key in val) {
        const element = val[key];

        let name = this.paymentTypes.find((paymentTypesitem) => {
          return paymentTypesitem.id == key;
        })
          ? this.paymentTypes.find((paymentTypesitem) => {
              return paymentTypesitem.id == key;
            }).name
          : $t("message.totaly");

        this.series.push({
          name: name,
          data: element[selected_item].map((item) => parseFloat(item.summ)),
        });
        chart.updateOptions({
          xaxis: {
            categories: element[selected_item].map((item) => item.data),
          },
        });
      }
    },
    async fetchData() {
      await this.period({
        start_date: this.value1[0],
        end_date: this.value1[1],
        report: 'payment_cost',
        entity: this.spec,
      })
        .then((res) => {
          for (const key in res.data) {
            this.$set(this.chart[key], 'period', res.data[key]);
          }
          this.setData(this.chart, 'period');
        })
        .catch ((error) => {
          console.error('Ошибка при выполнении запроса:', error);
        })
    },
    updateChart(bool) {
      this.$refs.chart.updateOptions({
        theme: {
          mode: bool ? "dark" : "light",
        },
      });
    },
  },

  computed: {
    ...mapGetters({
      paymentTypes: "paymentTypes/list",
      mode: "theme/MODE",
      token: 'auth/token',
    }),
  },
};
</script>

<style lang="scss" scoped>
.chart-card {
  // chart-card__statistic
  &__statistic {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    // chart-card__statistic-item
    &-item {
      .count {
        font-size: 20px;
        font-weight: 600;
        margin: 0;
        position: relative;
      }

      .subtitle {
        color: #9ca3af;
        font-size: 13px;
        margin: 0;
      }

      &:nth-child(2) .count::before,
      &:last-child .count::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -14px;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #8b5cf6;
      }

      &:last-child .count::before {
        background: #0ea5e9;
      }
    }
  }
}
</style>

<style lang="scss">
.chart-card__header .el-dropdown-link {
  margin: 0;
  border: none;
}
</style>