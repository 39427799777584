<template>
  <div class="chart-card">
    <div class="chart-card__header">
      <h2>{{ title }}</h2>
    </div>
    <table style="width: 100%" v-if="doctor" class="top-collectors-table">
      <div class="tbody">
        <tr>
          <td>
            <div class="clinic-report-title">
              <p>Количество пациентов</p>
            </div>
          </td>
          <td>
            <el-progress :stroke-width="8" :percentage="percentage(doctor)"></el-progress>
          </td>
          <td>
            <div>
              <h6>{{ doctor.bonus_amount | formatMoney }}</h6>
              <span>{{ doctor.paid_amount | formatMoney }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="clinic-report-title">
              <p>Общий приход в клинику</p>
            </div>
          </td>
          <td>
            <el-progress :stroke-width="8" :percentage="percentage(doctor)"></el-progress>
          </td>
          <td>
            <div>
              <h6>{{ doctor.bonus_amount | formatMoney }}</h6>
              <span>{{ doctor.paid_amount | formatMoney }}</span>
            </div>
          </td>
        </tr>
      </div>
    </table>
  </div>
</template>

<script>
export default {
  props: ["title", "doctor"],

  methods: {
    userName(item) {
      if (item.user) return item.user.name + " " + item.user.surname;
      if (item.partner_doctor) return item.partner_doctor.name;
      return "";
    },
    percentage(item) {
      return parseFloat(
        ((item.bonus_amount * 100) / this.doctor.sum_doctor_bonus).toFixed(2)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.top-collectors__header {
  padding: 12px 24px;
}

.top-collectors-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;

  .tbody {
    // max-height: 480px;
    overflow-y: auto;

    tr {
      vertical-align: middle;
      height: 80px;
    }
  }

  tr {
    td {
      border-bottom: 1px solid #eee;
      padding: 0 8px;

      &:first-child {
        padding-left: 24px;
        width: 200px;
        color: #222;
        font-weight: 500;

        div {
          display: inline-flex;
          align-items: center;
          gap: 12px;

          .el-avatar {
            flex-shrink: 0;
          }
        }
      }

      &:nth-child(2) {
        width: 300px;
      }

      &:last-child {
        padding-right: 24px;
        // text-align: right;
        width: 150px;

        h6 {
          margin: 0;
          color: #222;
        }

        span {
          color: #10b981;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
