import { sort } from "../properties/sort";
import { filter } from "./properties/filter";
import { pagination } from "../properties/pagination";
import { model } from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, patientBalances) => {
        state.list = patientBalances
    },
    SET_POCKET: (state, pocket) => {
        state.pocket = pocket
    },
    SET_POCKET_BALANCE: (state, pocket) => {
        state.pocket_balance = pocket && pocket.pocket ? pocket.pocket.price : null
    },
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_INVENTORY: (state, list) => state.inventory = list,
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_MODEL: (state, model) => {
        state.model = model;
    },
    SET_BLANCE_REPORT: (state, patientBalances) => {
        state.balance_report = patientBalances
    },
    SET_MEDCINE: (state, products) => {
        state.medcine = products.products;
        state.total_amount = products.total_amount;
    },
    SET_THERAPY: (state, products) => {
        state.therapy = products.products;
        state.therapy_amount = products.total_amount;
    },
    SET_BALANCE: (state, patientBalance) => {
        state.modal_balance = patientBalance;
    },
    SET_CLASSS: (state, classs) => {
        state.classs = classs.room_details;
        state.classs_amount = classs.total_amount;
    },
    SET_SEARCH_LIST: (state, patients) => (state.search_list = patients),
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    EMPTY_MODEL: (state) => {
        state.medcine = [];
        state.therapy = [];
        state.classs = [];
        state.total_amount = '';
        state.therapy_amount = '';
        state.classs_amount = '';
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    UPDATE_LANG_COLUMN: (state) => {
        state.columns.id.title = "№";
        state.columns.fullName.title = i18n.t('message.patient');
        state.columns.summa.title = i18n.t('message.total_amount');
        state.columns.contractNumber.title = i18n.t('message.paid');
        state.columns.zametka.title = i18n.t('message.notes');
        state.columns.typePatsent.title = i18n.t('message.number_card');
        state.columns.doctor.title = i18n.t('message.doctor');
        state.columns.data.title = i18n.t('message.date');
        state.columns.settings.title = i18n.t('message.settings');
    }
};
