<template>
  <div class="chart-card">
    <div class="chart-card__header">
      <h2>{{ title }}</h2>
      <div>
        <el-date-picker v-model="value1" type="daterange" :start-placeholder="$t('message.start_period')"
                        :end-placeholder="$t('message.end_period')" v-if="filterDate === 'period'">
        </el-date-picker>
      </div>
      <el-dropdown>
        <span class="el-dropdown-link">
          {{ $t(`message.${filterDate}`)
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
              v-for="item in filterByDate"
              @click.native="filterDate = item"
              :key="item"
          >{{ $t(`message.${item}`) }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div>
      <apexchart ref="chart" class="chart" type="donut" width="250" :options="chartOptions" :series="series"></apexchart>
    </div>

    <div class="candidates-wrap">
      <div class="candidates-wrap__item" v-for="(item, index) in candidatesInfo" :key="index">

        <span>{{ item.title }}</span>
        <p>
          {{ item.total | formatMoney }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
import {log} from "util";
export default {
  props: ['data', 'title', 'hospital', 'dayHospital', 'filter', 'getColors'],
  data() {
    return {
      show: false,
      series: [1, 1],
      filterDate: "daily",
      filterByDate: [
        "daily",
        "weekly",
        "monthly",
        "quarterly",
        "half_yearly",
        "yearly",
        "period",
      ],
      value1: null,
      custom_period_value: null,
      chartOptions: {
        theme: {
          mode: 'light'
        },
        chart: {
          background: 'transparent',
          width: 380,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            // endAngle: 270
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
        },
        legend: {
          show: false,
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        labels: [],
        colors: ["#8b5cf6", "#0ea5e9", "#f59e0b", "#10b981"],
      },
      candidatesInfo: [
        {
          title: this.hospital,
          total: 1233,
        },
        {
          title: this.dayHospital,
          total: 1233,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      mode: 'theme/MODE'
    })
  },
  watch: {
    data: function (val) {
      this.setData(val);
    },
    filterDate: function (newVal, oldVal) {
      if (newVal === 'period') {
        this.value1 = this.custom_period_value;
      }
      this.setData(this.data);
    },
    value1: function (val) {
      if (val) {
        this.custom_period_value = val;
        this.selected_item = "period";
        this.fetchData();
      }
    },
  },
  created() {
    if (this.getColors) this.chartOptions.colors = this.getColors
    console.log(this.chartOptions);
  },
  mounted() {
    this.updateChart(this.mode);
  },
  methods: {
    ...mapActions({
      period: "dashboards/customReport"
    }),
    setData(val) {
      this.show = false;
      this.series = Object.values(val[this.filterDate]);
      this.chartOptions.labels = [];
      this.chartOptions.labels.push(this.hospital);
      this.chartOptions.labels.push(this.dayHospital);

      this.candidatesInfo = [
        {
          title: this.hospital,
          total: Object.values(val[this.filterDate])[0],
        },
        {
          title: this.dayHospital,
          total: Object.values(val[this.filterDate])[1],
        },
      ];
      this.show = true;
    },
    async fetchData() {
      await this.period({
        start_date: this.value1[0],
        end_date: this.value1[1],
        report: 'hospital',
      })
        .then((res) => {
          this.$set(this.data, 'period', res.data);
          this.setData(this.data);
        })
        .catch ((error) => {
          console.error('Ошибка при выполнении запроса:', error);
        })
    },
    updateChart(bool) {
      this.$refs.chart.updateOptions({
        theme: {
          mode: bool ? 'dark' : 'light',
        },
      });
    },
  },
};
</script>

<style lang="scss">
.chart-card .chart>div {
  margin: 0 auto !important;
}
</style>

<style lang="scss" scoped>
.candidates-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-top: 1px solid #e5e7eb;

  // candidates-wrap__item
  &__item {
    padding: 1rem;
    text-align: center;

    &:first-child {
      border-right: 1px solid #e5e7eb;
    }

    span {
      color: #9ca3af;
      font-size: 14px;
    }

    p {
      font-weight: 500;
      font-size: 24px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      span i {
        display: inline-block;
        margin-right: 4px;
      }
    }
  }
}
</style>