import { columns } from './properties/columns'
import { model } from "./properties/model";
import { conclusion_logo_model } from "./properties/conclusion_logo_model";
import { rules } from "./properties/rules";

export const state = {
    conclusion_logo_model: JSON.parse(JSON.stringify(conclusion_logo_model)),
    model: JSON.parse(JSON.stringify(model)),
    columns: columns,
    phone_numbers: [],
    rules: rules
};
