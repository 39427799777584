<template>
    <div>
        <navbar></navbar>
        <div v-if="!!version" class="main-wrapper">
            <div class="main-wrapper__menu">
                <new-menu />
            </div>
            <app-main class="main-wrapper__pages"></app-main>
        </div>
        <app-main v-else></app-main>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { Navbar, AppMain } from './components'
import NewMenu from './components/NewMenu.vue'
export default {
    components: {
        AppMain,
        Navbar,
        NewMenu
    },
    computed: {
        ...mapState({
            version: state => +state.theme.version
        })
    },
}
</script>

<style lang="scss">
.main-wrapper {
    // display: grid;
    // grid-template-columns: 76.5px 1fr;

    &__menu {
        position: fixed;
        top: 0;
        z-index: 10;
    }
    &__pages{
        position: relative;
        margin-left: 100px;
        width: calc(100vw - 100px);
    }
}
</style>