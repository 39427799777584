<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || $t('message.purpose_of_hospitalization')"
    filterable
    clearable
  >
    <el-option
      v-for="item in purposeHospitalizations"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: ["purpose_hospitalization_id"],
  mounted() {
    if (
      this.purposeHospitalizations &&
      this.purposeHospitalizations.length === 0
    ) {
      this.getInventory();
    }
  },
  watch: {
    purpose_hospitalization_id: {
      handler: function (val, oldVal) {
        this.selected = val;
        this.$emit("input", this.selected);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      purposeHospitalizations: "purposeHospitalizations/inventory",
    }),
  },
  methods: {
    ...mapActions({
      getInventory: "purposeHospitalizations/inventory",
    }),
  },
};
</script>
