<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || $t('message.nationality')"
    filterable
    clearable
  >
    <el-option
      v-for="item in nationalities"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: ["nationality_id"],
  mounted() {
    if (this.nationalities && this.nationalities.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    nationality_id: {
      handler: function (val, oldVal) {
        this.selected = val;
        this.$emit("input", this.selected);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      nationalities: "nationalities/inventory",
    }),
  },
  methods: {
    ...mapActions({
      getInventory: "nationalities/inventory",
    }),
  },
};
</script>
