import {sort} from "../properties/sort";
import {filter} from "./properties/filter";
import {pagination} from "../properties/pagination";
import {model} from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, costs) => (state.list = costs),
    SET_FILTER: (state,filter) => (state.filter = filter),
    SET_PAGINATION: (state,pagination) => (state.pagination = pagination),
    SET_CHART: (state,chart) => (state.chart = chart),
    SET_SORT: (state,sort) => (state.sort = sort),
    SET_MODEL: (state,model) => {
        state.model.id = model.id;
        state.model.name = model.name;
        state.model.amount = model.amount;
        state.model.description = model.description;
        state.model.created_at = model.created_at;
        state.model.updated_at = model.updated_at;
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse( JSON.stringify( model ));
    },
    REFRESH: (state) => {
        state.filter = JSON.parse( JSON.stringify( filter ));
        state.sort = JSON.parse( JSON.stringify( sort ));
        state.pagination = JSON.parse( JSON.stringify( pagination ));
    },
    UPDATE_LANG_COLUMN: (state) => {
        state.columns.id.title = "№";
        state.columns.name.title =  i18n.t('message.name');
        state.columns.amount.title =  i18n.t('message.amount');
        state.columns.description.title =  i18n.t('message.description');
        state.columns.created_at.title =  i18n.t('message.date');
        state.columns.updated_at.title =  i18n.t('message.update_date');
        state.columns.settings.title =  i18n.t('message.settings');
    }
};
