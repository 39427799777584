<template>
  <aside class="theme">
    <h2 class="theme__title">{{ $t("message.theme_setting") }}</h2>

    <div class="theme-close mobile" @click="$emit('close-draw')"><i class="el-icon-close"></i></div>

    <!-- header -->
    <el-radio-group v-model="version" style="width: 100%">
      <h3 class="costum-radio-label">{{ $t("message.version") }}</h3>

      <el-skeleton style="width: 100%" :loading="loading" animated>
        <!-- skeleton loader -->
        <template slot="template">
          <div class="theme-header">
            <div>
              <figure class="costum-radio">
                <div class="costum-radio__img">
                  <el-skeleton-item variant="image" style="width: 100%; height: 150px" />
                </div>
                <figcaption class="costum-radio__label">
                  <el-skeleton-item variant="text" style="width: 30%" />
                </figcaption>
              </figure>
            </div>
            <div>
              <figure class="costum-radio">
                <div class="costum-radio__img">
                  <el-skeleton-item variant="image" style="width: 100%; height: 150px" />
                </div>
                <figcaption class="costum-radio__label">
                  <el-skeleton-item variant="text" style="width: 30%" />
                </figcaption>
              </figure>
            </div>
          </div>
        </template>

        <!-- content -->
        <template>
          <div class="theme-header">
            <el-radio :label="1">
              <figure class="costum-radio">
                <div class="costum-radio__img">
                  <img src="https://picsum.photos/id/25/800/500" alt="" />
                </div>
                <figcaption class="costum-radio__label">
                  {{ $t("message.new") }}
                </figcaption>
              </figure>
            </el-radio>
            <el-radio :label="0">
              <figure class="costum-radio">
                <div class="costum-radio__img">
                  <img src="https://picsum.photos/id/26/800/500" alt="" />
                </div>
                <figcaption class="costum-radio__label">
                  {{ $t("message.old") }}
                </figcaption>
              </figure>
            </el-radio>
          </div>
        </template>
      </el-skeleton>
    </el-radio-group>

    <!-- body -->
    <el-radio-group v-if="version" v-model="backgroundImage" style="width: 100%">
      <div style="display: flex; align-items: center; gap: 1rem">
        <h3 class="costum-radio-label">{{ $t("message.dynamic_photo") }}</h3>
        <el-radio-group v-model="themeMode">
          <el-radio :label="0">{{ $t("message.light") }}</el-radio>
          <el-radio :label="1">{{ $t("message.dark") }}</el-radio>
        </el-radio-group>
      </div>
      <!-- wallpapers -->
      <el-skeleton style="width: 100%" :loading="loading" animated>
        <!-- skeleton loader -->
        <template slot="template">
          <div class="theme-body">
            <div v-for="item in 30" :key="item">
              <figure class="costum-radio">
                <div class="costum-radio__img">
                  <el-skeleton-item variant="image" style="width: 100%; height: 100px" />
                </div>
              </figure>
            </div>
          </div>
        </template>

        <!-- content -->
        <template>
          <div class="theme-body">
            <el-radio v-for="item in wallpapersFilter(themeMode)" :key="item.id" :label="item.id">
              <figure class="costum-radio">
                <div class="costum-radio__img">
                  <img :src="require(`../../static/background/${item.small}`)" :alt="item.small" />
                </div>
              </figure>
            </el-radio>
          </div>
        </template>
      </el-skeleton>
    </el-radio-group>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    loading: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      version: 1,
      themeMode: 0,
      backgroundImage: 1,
      wallpapers: [
        /* 
          mode: 0 -> light
          mode: 1 -> dark
        */
        {
          id: 1,
          url: "light/full/0.jpg",
          small: "light/small/0.png",
          mode: 0,
        },
        {
          id: 2,
          url: "light/full/1.jpg",
          small: "light/small/1.jpg",
          mode: 0,
        },
        {
          id: 3,
          url: "light/full/2.jpg",
          small: "light/small/2.jpg",
          mode: 0,
        },
        {
          id: 4,
          url: "light/full/3.png",
          small: "light/small/3.png",
          mode: 0,
        },
        {
          id: 5,
          url: "light/full/4.jpg",
          small: "light/small/4.jpg",
          mode: 0,
        },
        {
          id: 6,
          url: "light/full/5.jpg",
          small: "light/small/5.png",
          mode: 0,
        },
        {
          id: 7,
          url: "light/full/6.jpg",
          small: "light/small/6.png",
          mode: 0,
        },
        {
          id: 8,
          url: "light/full/7.jpg",
          small: "light/small/7.png",
          mode: 0,
        },
        {
          id: 9,
          url: "light/full/8.jpg",
          small: "light/small/8.png",
          mode: 0,
        },
        {
          id: 10,
          url: "light/full/9.jpg",
          small: "light/small/9.jpg",
          mode: 0,
        },

        // dark
        {
          id: 11,
          url: "dark/full/0.jpg",
          small: "dark/small/0.png",
          mode: 1,
        },
        {
          id: 12,
          url: "dark/full/1.jpg",
          small: "dark/small/1.jpg",
          mode: 1,
        },
        {
          id: 13,
          url: "dark/full/2.jpg",
          small: "dark/small/2.jpg",
          mode: 1,
        },
        {
          id: 14,
          url: "dark/full/3.jpg",
          small: "dark/small/3.jpg",
          mode: 1,
        },
        {
          id: 15,
          url: "dark/full/4.jpg",
          small: "dark/small/4.jpg",
          mode: 1,
        },
        {
          id: 16,
          url: "dark/full/5.jpg",
          small: "dark/small/5.jpg",
          mode: 1,
        },
        {
          id: 17,
          url: "dark/full/6.jpg",
          small: "dark/small/6.jpg",
          mode: 1,
        },
        {
          id: 18,
          url: "dark/full/7.jpg",
          small: "dark/small/7.jpg",
          mode: 1,
        },
        {
          id: 19,
          url: "dark/full/8.jpg",
          small: "dark/small/8.png",
          mode: 1,
        },
        {
          id: 20,
          url: "dark/full/9.jpg",
          small: "dark/small/9.png",
          mode: 1,
        },
        {
          id: 21,
          url: "dark/full/10.jpg",
          small: "dark/small/10.png",
          mode: 1,
        },
        {
          id: 22,
          url: "dark/full/11.jpg",
          small: "dark/small/11.png",
          mode: 1,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      mode: "theme/MODE",
    }),
    // for light or dark images
    wallpapersFilter() {
      return (mode) => {
        return this.wallpapers.filter((item) => item.mode === mode);
      };
    },
  },

  watch: {
    version(v) {
      this.$store.commit("theme/SET_VERSION", v);
    },
    backgroundImage(id) {
      this.$store.commit("theme/SET_THEME", this.wallpapers[id - 1]);
    },
    mode(evt) {
      if (this.version && evt) {
        document.body.classList.add("dark-mode");
      } else {
        document.body.classList.remove("dark-mode");
      }
    },
  },
  mounted() {
    const V = this.$store.state.theme.version;
    const themeId = this.$store.state.theme.theme.id;

    this.version = typeof V == "string" ? +V : V;
    this.backgroundImage = typeof themeId == "string" ? +themeId : themeId;
    this.themeMode = !!this.mode ? 1 : 0;
  },
};
</script>

<style lang="scss">
.theme {
  padding: 20px;
  // background: #303030;
  height: 100%;

  // theme__title
  &__title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  // theme-header
  &-header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-bottom: 1.5rem;
  }

  // theme-body
  &-body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    max-height: 564px;
    overflow-y: auto;

    .costum-radio {
      height: 100px;
    }
  }
}

.costum-radio-label {
  font-size: 16px;
  font-weight: 700;
  color: #212529;
}

.costum-radio {
  border: 2px solid #d1d5db;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  transition: border 0.25s ease-in-out;
  margin: 0;

  &:hover {
    border-color: #0ea5e9 !important;
  }

  // costum-radio__img
  &__img {
    width: 100%;
    height: 150px;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  // costum-radio__label
  &__label {
    text-align: center;
    color: #212529;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 0;
  }
}

.theme-header .el-radio,
.theme-body .el-radio {
  width: 100%;
  margin: 0;
  padding: 0;

  &__input {
    display: none;
  }

  &__label {
    display: block;
    width: 100%;
    padding: 0;
  }

  &.is-checked .costum-radio {
    border-color: #0ea5e9 !important;
  }
}
</style>